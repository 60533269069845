import SvgIcon from '@mui/material/SvgIcon';


export function ConciergeIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 109.69 109.69">
    <path d="M3.92,94H105.77a3.93,3.93,0,0,0,3.92-3.92A54.85,54.85,0,0,0,58.76,35.45V31.34H66.6a3.92,3.92,0,1,0,0-7.83H43.09a3.92,3.92,0,0,0,0,7.83h7.84v4.11A54.85,54.85,0,0,0,0,90.1,3.93,3.93,0,0,0,3.92,94Z"/>
    <path d="M105.77,101.86H3.92a3.92,3.92,0,0,0,0,7.83H105.77a3.92,3.92,0,1,0,0-7.83Z"/>
    <path d="M54.85,15.67a3.91,3.91,0,0,0,3.91-3.92V3.92A3.93,3.93,0,0,0,56.81.52a4,4,0,0,0-3.92,0,3.93,3.93,0,0,0-2,3.4v7.83a3.93,3.93,0,0,0,3.92,3.92Z"/>
    <path d="M10.54,31.73a3.91,3.91,0,0,0,5.52-5.52l-5.52-5.56A3.93,3.93,0,1,0,5,26.21Z"/>
    <path d="M96.41,32.87a4,4,0,0,0,2.78-1.14l5.53-5.52h0a3.92,3.92,0,0,0-5.53-5.53l-5.56,5.53a3.91,3.91,0,0,0,0,5.56,4,4,0,0,0,2.78,1.14Z"/>
    </SvgIcon>
  );
}


export function FacebookIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M20 3H4C3.447 3 3 3.448 3 4V20C3 20.552 3.447 21 4 21H12.615V14.04H10.277V11.315H12.615V9.315C12.615 6.99 14.035 5.723 16.115 5.723C16.814 5.721 17.514 5.757 18.21 5.83V8.25H16.775C15.647 8.25 15.427 8.788 15.427 9.575V11.31H18.124L17.774 14.035H15.426V21H20C20.553 21 21 20.552 21 20V4C21 3.448 20.553 3 20 3Z"/>
    </SvgIcon>
  );
}


export function InstagramIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M11.999 7.37688C9.44495 7.37688 7.37595 9.44688 7.37595 11.9999C7.37595 14.5539 9.44495 16.6239 11.999 16.6239C14.551 16.6239 16.622 14.5539 16.622 11.9999C16.622 9.44688 14.551 7.37688 11.999 7.37688ZM11.999 15.0039C10.34 15.0039 8.99495 13.6589 8.99495 12.0009C8.99495 10.3419 10.34 8.99788 11.999 8.99788C13.658 8.99788 15.001 10.3419 15.001 12.0009C15.001 13.6589 13.658 15.0039 11.999 15.0039Z"/>
      <path d="M16.806 8.28488C17.4013 8.28488 17.884 7.80225 17.884 7.20688C17.884 6.61152 17.4013 6.12888 16.806 6.12888C16.2106 6.12888 15.728 6.61152 15.728 7.20688C15.728 7.80225 16.2106 8.28488 16.806 8.28488Z"/>
      <path d="M20.533 6.11088C20.064 4.90188 19.109 3.94588 17.9 3.47888C17.201 3.21588 16.462 3.07488 15.714 3.05888C14.751 3.01688 14.446 3.00488 12.004 3.00488C9.56195 3.00488 9.24895 3.00488 8.29395 3.05888C7.54795 3.07388 6.80895 3.21488 6.10995 3.47888C4.89995 3.94588 3.94495 4.90188 3.47695 6.11088C3.21395 6.81088 3.07295 7.54888 3.05795 8.29688C3.01495 9.25888 3.00195 9.56388 3.00195 12.0069C3.00195 14.4489 3.00195 14.7599 3.05795 15.7169C3.07295 16.4649 3.21395 17.2029 3.47695 17.9039C3.94595 19.1119 4.90095 20.0679 6.11095 20.5359C6.80695 20.8079 7.54595 20.9619 8.29595 20.9859C9.25895 21.0279 9.56395 21.0409 12.006 21.0409C14.448 21.0409 14.761 21.0409 15.716 20.9859C16.463 20.9709 17.202 20.8289 17.902 20.5669C19.111 20.0979 20.066 19.1429 20.535 17.9339C20.798 17.2339 20.939 16.4959 20.954 15.7479C20.997 14.7859 21.01 14.4809 21.01 12.0379C21.01 9.59488 21.01 9.28488 20.954 8.32788C20.941 7.56988 20.801 6.81888 20.533 6.11088ZM19.315 15.6429C19.308 16.2189 19.204 16.7899 19.004 17.3309C18.699 18.1179 18.078 18.7399 17.292 19.0419C16.757 19.2409 16.193 19.3449 15.622 19.3529C14.672 19.3969 14.404 19.4079 11.968 19.4079C9.52995 19.4079 9.28095 19.4079 8.31295 19.3529C7.74395 19.3459 7.17795 19.2409 6.64395 19.0419C5.85495 18.7409 5.22995 18.1189 4.92495 17.3309C4.72895 16.7969 4.62295 16.2319 4.61395 15.6619C4.57095 14.7119 4.56095 14.4439 4.56095 12.0079C4.56095 9.57088 4.56095 9.32188 4.61395 8.35288C4.62095 7.77688 4.72495 7.20688 4.92495 6.66588C5.22995 5.87688 5.85495 5.25588 6.64395 4.95388C7.17795 4.75588 7.74395 4.65088 8.31295 4.64288C9.26395 4.59988 9.53095 4.58788 11.968 4.58788C14.405 4.58788 14.655 4.58788 15.622 4.64288C16.193 4.64988 16.757 4.75488 17.292 4.95388C18.078 5.25688 18.699 5.87888 19.004 6.66588C19.2 7.19988 19.306 7.76488 19.315 8.33488C19.358 9.28588 19.369 9.55288 19.369 11.9899C19.369 14.4259 19.369 14.6879 19.326 15.6439H19.315V15.6429Z"/>
    </SvgIcon>
  );
}


export function TwitterIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M19.633 7.99704C19.646 8.17204 19.646 8.34604 19.646 8.52004C19.646 13.845 15.593 19.981 8.186 19.981C5.904 19.981 3.784 19.32 2 18.172C2.324 18.209 2.636 18.222 2.973 18.222C4.856 18.222 6.589 17.586 7.974 16.501C6.203 16.464 4.719 15.304 4.207 13.708C4.456 13.745 4.706 13.77 4.968 13.77C5.329 13.77 5.692 13.72 6.029 13.633C4.182 13.259 2.799 11.638 2.799 9.68004V9.63004C3.336 9.92904 3.959 10.116 4.619 10.141C3.534 9.41904 2.823 8.18404 2.823 6.78704C2.823 6.03904 3.022 5.35304 3.371 4.75504C5.354 7.19804 8.335 8.79504 11.677 8.97004C11.615 8.67004 11.577 8.35904 11.577 8.04704C11.577 5.82704 13.373 4.01904 15.605 4.01904C16.765 4.01904 17.812 4.50504 18.548 5.29104C19.458 5.11604 20.33 4.77904 21.104 4.31804C20.805 5.25304 20.168 6.03904 19.333 6.53804C20.144 6.45004 20.93 6.22604 21.652 5.91404C21.104 6.71204 20.419 7.42304 19.633 7.99704Z"/>
    </SvgIcon>
  );
}


export function YoutubeIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M21.593 7.20301C21.363 6.34501 20.688 5.66801 19.831 5.43701C18.265 5.00701 12 5.00001 12 5.00001C12 5.00001 5.73596 4.99301 4.16896 5.40401C3.32896 5.63301 2.63496 6.32501 2.40296 7.18201C1.98996 8.74801 1.98596 11.996 1.98596 11.996C1.98596 11.996 1.98196 15.26 2.39196 16.81C2.62196 17.667 3.29696 18.344 4.15496 18.575C5.73696 19.005 11.985 19.012 11.985 19.012C11.985 19.012 18.25 19.019 19.816 18.609C20.672 18.379 21.35 17.703 21.583 16.846C21.997 15.281 22 12.034 22 12.034C22 12.034 22.02 8.76901 21.593 7.20301ZM9.99596 15.005L10.001 9.00501L15.208 12.01L9.99596 15.005Z"/>
    </SvgIcon>
  );
}


export function TikTokIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 333335 333336">
            <path d="M72464 0h188407c39856 0 72464 32609 72464 72465v188407c0 39855-32608 72464-72464 72464H72464C32608 333336 0 300727 0 260872V72465C0 32609 32608 0 72464 0zm127664 70642c337 2877 825 5661 1461 8341l6304 2c1170 9991 4006 19119 8465 26697 7282 6745 16797 10904 28280 11641v9208c2131 444 4350 746 6659 894v29690c-14847 1462-27841-3426-42981-12531l2324 50847c0 16398 61 23892-8738 38977-20546 35222-58194 36677-82176 18323-12269-4256-23069-12466-29881-23612-19875-32516-1959-85512 55687-90966l-94 7835v1970c3105-646 6365-1144 9794-1468v31311c-12484 2057-20412 5890-24119 12980-7424 14197-4049 26526 3716 34309 16276 2796 34401-8481 31673-43351V70644h33628z" fill="#1a121f"/>
            <path d="M200128 70642c3093 26406 18915 45038 44510 46681v25046l-165 15v-21275c-25595-1642-40311-17390-43404-43796l-27114-1v111095c3912 50005-35050 51490-49955 32531 17482 10934 45867 3826 42501-39202V70641h33628zm-72854 184165c-15319-3153-29249-12306-37430-25689-19875-32516-1959-85512 55687-90966l-94 7835c-53444 8512-58809 65920-44009 89802 5707 9209 15076 15686 25846 19019z" fill="#26f4ee"/>
            <path d="M207892 78985c1761 15036 7293 28119 16454 36903-12866-6655-20630-19315-23062-36905l6609 2zm36580 47511c2181 463 4456 777 6824 929v29690c-14847 1462-27841-3426-42981-12531l2324 50847c0 16398 61 23892-8738 38977-21443 36760-61517 36743-85239 15810 30930 17765 84928 3857 84829-56453v-55496c15141 9105 28134 13993 42981 12530v-24302zm-99036 21460c3105-646 6365-1144 9794-1468v31311c-12484 2057-20412 5890-24119 12980-10441 19964 474 36238 14923 41365-18075-649-36010-19214-23555-43031 3707-7089 10474-10923 22958-12980v-28177z" fill="#fb2c53"/>
            <path d="M201068 77313c3093 26406 17809 42154 43404 43796v29689c-14847 1462-27841-3425-42981-12530v55496c119 72433-77802 77945-100063 42025-14800-23881-9435-81290 44009-89802v30147c-12483 2057-19250 5891-22958 12980-22909 43808 56997 69872 51475-706V77313l27114 1z" fill="#fefefe"/>
        </SvgIcon>
    );
}


export function ThreadsIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 512 512">
            <path  fillRule="nonzero" d="M337.36 243.58c-1.46-.7-2.95-1.38-4.46-2.02-2.62-48.36-29.04-76.05-73.41-76.33-25.6-.17-48.52 10.27-62.8 31.94l24.4 16.74c10.15-15.4 26.08-18.68 37.81-18.68h.4c14.61.09 25.64 4.34 32.77 12.62 5.19 6.04 8.67 14.37 10.39 24.89-12.96-2.2-26.96-2.88-41.94-2.02-42.18 2.43-69.3 27.03-67.48 61.21.92 17.35 9.56 32.26 24.32 42.01 12.48 8.24 28.56 12.27 45.26 11.35 22.07-1.2 39.37-9.62 51.45-25.01 9.17-11.69 14.97-26.84 17.53-45.92 10.51 6.34 18.3 14.69 22.61 24.73 7.31 17.06 7.74 45.1-15.14 67.96-20.04 20.03-44.14 28.69-80.55 28.96-40.4-.3-70.95-13.26-90.81-38.51-18.6-23.64-28.21-57.79-28.57-101.5.36-43.71 9.97-77.86 28.57-101.5 19.86-25.25 50.41-38.21 90.81-38.51 40.68.3 71.76 13.32 92.39 38.69 10.11 12.44 17.73 28.09 22.76 46.33l28.59-7.63c-6.09-22.45-15.67-41.8-28.72-57.85-26.44-32.53-65.1-49.19-114.92-49.54h-.2c-49.72.35-87.96 17.08-113.64 49.73-22.86 29.05-34.65 69.48-35.04 120.16v.24c.39 50.68 12.18 91.11 35.04 120.16 25.68 32.65 63.92 49.39 113.64 49.73h.2c44.2-.31 75.36-11.88 101.03-37.53 33.58-33.55 32.57-75.6 21.5-101.42-7.94-18.51-23.08-33.55-43.79-43.48zm-76.32 71.76c-18.48 1.04-37.69-7.26-38.64-25.03-.7-13.18 9.38-27.89 39.78-29.64 3.48-.2 6.9-.3 10.25-.3 11.04 0 21.37 1.07 30.76 3.13-3.5 43.74-24.04 50.84-42.15 51.84z"/>
        </SvgIcon>
    );
}

export function SnapchatIcon(props) {
    return (
       <SvgIcon {...props} viewBox="0 0 512 512">
            <rect fill="#FFFC00" width="512" height="512" rx="105" ry="105"/>
            <g fillRule="nonzero">
                <path fill="#fff" stroke="#000" strokeWidth="8" d="M424.16 341.5c-1.47-4.87-8.51-8.3-8.51-8.3v.01c-.65-.37-1.25-.67-1.76-.92-11.72-5.67-22.1-12.47-30.86-20.22-7.03-6.22-13.05-13.07-17.89-20.37-5.9-8.89-8.67-16.32-9.87-20.34-.66-2.62-.55-3.66 0-5.03.47-1.14 1.81-2.25 2.47-2.75 3.96-2.8 10.33-6.93 14.24-9.46 3.39-2.19 6.31-4.09 8.01-5.27 5.51-3.85 9.27-7.77 11.49-11.99 2.88-5.47 3.22-11.48.98-17.41-3.02-7.98-10.47-12.75-19.94-12.75-2.11 0-4.27.24-6.42.71-5.42 1.17-10.57 3.1-14.88 4.78a.472.472 0 01-.64-.46c.46-10.67.97-25-.21-38.64-1.07-12.33-3.6-22.72-7.75-31.77-4.17-9.09-9.58-15.82-13.82-20.67-4.05-4.64-11.13-11.45-21.83-17.57-15.07-8.62-32.22-12.99-50.97-12.99-18.71 0-35.84 4.37-50.92 12.98-11.33 6.47-18.57 13.79-21.88 17.58-4.24 4.85-9.64 11.58-13.81 20.67-4.15 9.05-6.69 19.44-7.75 31.77-1.19 13.66-.71 26.87-.22 38.63.02.35-.33.59-.65.47-4.3-1.68-9.46-3.61-14.87-4.78-2.15-.47-4.31-.71-6.42-.71-9.47 0-16.92 4.77-19.94 12.75-2.24 5.93-1.9 11.94.98 17.41 2.23 4.22 5.99 8.14 11.49 11.99 1.7 1.18 4.62 3.08 8.01 5.27 3.83 2.48 10.02 6.49 14 9.28.49.36 2.17 1.62 2.7 2.93.57 1.4.67 2.46-.05 5.24-1.24 4.06-4.01 11.39-9.8 20.13-4.85 7.3-10.87 14.15-17.9 20.37-8.76 7.75-19.14 14.55-30.86 20.22-.55.27-1.21.61-1.93 1.02v-.01s-7 3.58-8.32 8.2c-1.95 6.83 3.24 13.22 8.56 16.65 8.68 5.59 19.25 8.6 25.38 10.24 1.7.45 3.25.87 4.66 1.31.88.29 3.09 1.12 4.03 2.33 1.19 1.53 1.33 3.43 1.76 5.56.68 3.59 2.17 8.06 6.62 11.12 4.88 3.38 11.09 3.62 18.95 3.92 8.22.32 18.46.71 30.16 4.57 5.43 1.8 10.35 4.82 16.04 8.31 11.89 7.31 26.69 16.4 51.98 16.4 25.3 0 40.2-9.14 52.17-16.49 5.66-3.47 10.55-6.47 15.85-8.22 11.71-3.87 21.94-4.26 30.16-4.57 7.86-.3 14.07-.54 18.96-3.92 4.75-3.28 6.13-8.17 6.75-11.87.33-1.83.56-3.46 1.61-4.81.89-1.15 2.93-1.95 3.89-2.29 1.45-.45 3.05-.88 4.82-1.35 6.13-1.64 13.81-3.58 23.16-8.86 11.19-6.32 11.95-14.17 10.79-18.03z"/>
            </g>
        </SvgIcon>
    );
}


export function LinkedInIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 122.88 122.31">
            <path fill="#0a66c2" fillRule="evenodd" d="M27.75,0H95.13a27.83,27.83,0,0,1,27.75,27.75V94.57a27.83,27.83,0,0,1-27.75,27.74H27.75A27.83,27.83,0,0,1,0,94.57V27.75A27.83,27.83,0,0,1,27.75,0Z"/>
            <path fill="#fff" fillRule="evenodd" d="M49.19,47.41H64.72v8h.22c2.17-3.88,7.45-8,15.34-8,16.39,0,19.42,10.2,19.42,23.47V98.94H83.51V74c0-5.71-.12-13.06-8.42-13.06s-9.72,6.21-9.72,12.65v25.4H49.19V47.41ZM40,31.79a8.42,8.42,0,1,1-8.42-8.42A8.43,8.43,0,0,1,40,31.79ZM23.18,47.41H40V98.94H23.18V47.41Z"/>
        </SvgIcon>
    );
}


export function PinterestIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 512 512">
            <path fill="#E60019" fillRule="nonzero" d="M0 256c0 109.29 68.5 202.6 164.91 239.32-2.35-19.99-4.84-52.95.53-76.07 4.63-19.89 29.89-126.68 29.89-126.68s-7.62-15.25-7.62-37.85c0-35.41 20.53-61.87 46.11-61.87 21.76 0 32.25 16.33 32.25 35.89 0 21.87-13.93 54.55-21.12 84.87-5.99 25.36 12.74 46.05 37.74 46.05 45.29 0 80.13-47.77 80.13-116.71 0-61.04-43.86-103.68-106.48-103.68-72.48 0-115.04 54.38-115.04 110.59 0 21.91 8.42 45.38 18.96 58.16a7.568 7.568 0 012.07 5.21c0 .7-.1 1.41-.29 2.09-1.94 8.07-6.26 25.37-7.08 28.9-1.13 4.65-3.69 5.66-8.54 3.4-31.82-14.81-51.71-61.34-51.71-98.71 0-80.41 58.4-154.22 168.36-154.22 88.41 0 157.13 63 157.13 147.18 0 87.83-55.37 158.53-132.25 158.53-25.84 0-50.09-13.45-58.41-29.3 0 0-12.78 48.68-15.88 60.59-6.01 23.13-22.7 52.39-33.04 69.01 23.84 7.36 49.14 11.3 75.38 11.3 141.38 0 256-114.63 256-256S397.38 0 256 0 0 114.62 0 256z"/>
        </SvgIcon>
    );
}