import React from 'react'
import { makeStyles } from '@mui/styles'
import { Box, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

const actionPanelHeights = {
  step: 165,
  stepDevice: 305,
  product: 170,
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
    position: 'fixed',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.contrast,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflowX: 'hidden',
    overflowY: 'auto',
    width: '100%',
  },
  absoluteRow: {
    position: 'absolute',
    top: 0,
    left: 'auto',
    right: 'auto',
    zIndex: 1,
    width: '100%',
    maxWidth: 800,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(2.5),
  },
  buttonRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(0.5),
  },
  closeBtn: {
    backgroundColor: `${theme.palette.primary.contrast + 'a6'} !important`,
    padding: theme.spacing(1.5) + '!important',
  },
  icon: {
    fontSize: '24px !important',
    color: theme.palette.primary.main,
  },
  stepInfoContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    overflow: 'auto',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
  },
  actionsSection: {
    position: 'absolute',
    bottom: 0,
    left: 'auto',
    right: 'auto',
    maxWidth: 800,
    width: '100%',
    background: `${theme.palette.primary.contrast}aa`,
    padding: theme.spacing(2.5, 0, 2.5),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    boxShadow: `0px 4px 4px 0px ${theme.palette.primary.contrast}40`,
    backdropFilter: 'blur(5px)',
    '& .step': { height: actionPanelHeights.step },
    '& .step-device': { height: actionPanelHeights.stepDevice },
    '& .product': { height: actionPanelHeights.product },
  },
  actionsContainer: { padding: theme.spacing(0, 2.5) },
  pageContent: {
    maxWidth: 800,
    width: '100%',
  },
}))

export default function ScrollablePage({
  disableActions, modal = null, actionPanel = null, onClose, contentType, hasDevice,
  contentRef = null, handleContentScroll = () => { }, pageContent = null, header
}) {
  const classes = useStyles()
  const actionsSectionRef = React.useRef(null)
  const [actionsSectionHeight, setActionsSectionHeight] = React.useState(0)
  let extraClass = contentType === 'step'
    ? (!!hasDevice ? 'step-device' : 'step')
    : contentType === 'product'
      ? 'product'
      : ''

  React.useEffect(() => {
    setActionsSectionHeight(actionsSectionRef.current?.clientHeight ?? 0)
  })

  return (<div className={classes.container}>
    {modal}
    <div className={classes.content}>
      <div
        ref={contentRef}
        onScroll={handleContentScroll}
        className={classes.stepInfoContainer}
        style={{
          paddingBottom: contentType === 'step'
            ? (!!hasDevice ? actionPanelHeights.stepDevice : actionPanelHeights.step)
            : contentType === 'product'
              ? !!actionsSectionHeight
                ? actionsSectionHeight + 20
                : actionPanelHeights.product
              : 0
        }}
      >
        <div className={classes.absoluteRow}>
          <Box className={classes.buttonRow}>
            <IconButton onClick={onClose} className={classes.closeBtn}>
              <CloseIcon className={classes.icon} />
            </IconButton>
          </Box>
        </div>
        {!!header && header}
        <div className={classes.pageContent}>
          {pageContent}
        </div>
      </div>
      {!disableActions && (
        <div ref={actionsSectionRef} className={`${classes.actionsSection} ${extraClass}`}>
          <div className={classes.actionsContainer}>
            {actionPanel}
          </div>
        </div>
      )}
    </div>
  </div>)
}
