import React, { useContext } from 'react';
import {
  Button,
  Typography,
  Box,
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import SignaturePad from 'react-signature-canvas'
import { uploadImage } from 'api.js'
import { useTranslation } from 'react-i18next';
import { AppContext } from 'App';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
  },
  signature_box: {
    width: '100%',
    maxWidth: '500px',
    height: '100px',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '10px',
    backgroundColor: 'white',
    marginBottom: 10
  },
  content: {
    padding: theme.spacing(5, 2.5, 0),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
    flex: 1,
    marginBottom: 20,
    maxWidth: 800,
    width: "calc(100% - 40px)",
  },
  signaturePadContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    paddingBottom: 40,
    maxWidth: 502,
  },
}))


export default function AgreementPage({ setView, handleVerify }) {
  const { t } = useTranslation()
  const { kc } = useContext(AppContext)
  const [selectedAgreement, setSelectedAgreement] = React.useState(0)
  const [sigPad, setSigpad] = React.useState(null)
  const classes = useStyles()
  const [disabled, setDisabled] = React.useState(true);
  let components = kc?.components || {}

  const clear = () => {
    sigPad.clear()
  }

  const handleSubmit = () => {
    setDisabled(true)
    let data = sigPad.getTrimmedCanvas().toDataURL('image/png')
    uploadImage(data).then(image_url => {
      handleVerify({ signature_url: image_url, agreed: true, message: components.agreement[selectedAgreement].message })
    })
  }

  return <div className={classes.container}>
    <div className={classes.content}>
      <Button
        style={{ padding: 0, minWidth: 'fit-content', alignSelf: 'flex-start' }}
        onClick={() => { setView("verify") }}
        startIcon={<ArrowBackIosNewIcon />}
      >{t("back")}</Button>
      <Typography variant="h3">{components.agreement[selectedAgreement].name}</Typography>
      <Box mt={1} mb={2}>
        {components.agreement[selectedAgreement].message.split("\n").map((i, key) => (
          <Typography key={key} sx={{overflowWrap: "break-word"}}>{i}<br /></Typography>
        ))}
      </Box>
    </div>
    <div className={["footer", classes.signaturePadContainer].join(" ")}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <SignaturePad
          canvasProps={{ className: classes.signature_box, id: "signaturePad" }}
          ref={ref => setSigpad(ref)}
          onEnd={() => setDisabled(sigPad.isEmpty())}
        />
      </div>
      <Button id="acceptButton"
        style={{ borderRadius: '12px', fontSize: '22px', padding: 10 }} onClick={() => handleSubmit()}
        fullWidth size="large" variant="contained"
        disabled={disabled}
      >
        {t("submit_signature")}
      </Button>
    </div>
  </div>
}