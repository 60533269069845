import React from 'react';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';


export default function LoadingScreen() {
	return <main>
		<Box pt={5} px={2.5}>
			<div style={{ flex: 1, width: '100%' }}>
				<Typography variant='h2'>Whoops!</Typography>
				<Typography style={{ marginBottom: 20 }} variant='h3'>This link is invalid</Typography>

				<Typography>
					<ul>
						<li>Please double check that you entered the correct link as it appears in your check-in message.</li>
						<li>If you're still having issues, please contact your host or email us at <a href="mailto:guest-support@ensoconnect.com">guest-support@ensoconnect.com</a></li>
					</ul>
				</Typography>
			</div>
		</Box>
	</main>
}