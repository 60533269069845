import '../App.css';
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useParams } from "react-router-dom";
import 'react-html5-camera-photo/build/css/index.css';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
// libs
import { loadData, loadProductData, verifyPost, unlockPost } from 'api.js';
import getTheme from 'theme.js'
// routes
import CustomIDScanner from 'components/CustomIDScanner';
import LoadingScreen from 'components/Loading.jsx';
import VerifiedPage from 'components/Verified.jsx';
import Verify from "components/Verify.jsx";
import ErrorPage from 'components/Error.jsx';
import StartPage from 'components/Start.jsx';
import AgreementPage from 'components/Agreement.jsx';
import CollectDetailsPage from 'components/CollectDetails';
import FeedbackPage from 'components/Feedback.jsx'
import { useTranslation } from 'react-i18next';
import { isAddressValid } from 'helpers';
import { AppContext } from 'App';
import { makeStyles } from '@mui/styles';
import { isValidAge } from 'utils';

const productViews = [
  'payment_link', // Upsell
  'flex', 'deposit', 'fee', 'pet_fee', 'custom_fee', // Fees
  'check_in_steps', 'check_out_steps', // Steps
  'guidebook' // Guidebooks
]


const useStyles = makeStyles(theme => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100vw',
    maxWidth: '100vw',
    '&.hide': { opacity: 0 },
  },
}))


const AppPages = ({
  setIsLoading, setListingId, setTheme
}) => {
  const classes = useStyles()
  const flags = useFlags();
  const { listing_id } = useParams();
  const { i18n } = useTranslation();
  const location = useLocation();
  const { kc, auth, view, setKc, setView, setAuth } = useContext(AppContext)
  const prevViewRef = React.useRef(null)
  const skipKCUpdate = React.useRef(false)
  const [didMount, setDidMount] = useState(false)
  const [userCountryCode, setUserCountryCode] = React.useState(null)
  let components = kc?.components ?? null


  useEffect(() => {
    window.scrollTo({ top: 0 })
    setDidMount(prev => true)
  }, [])

  useEffect(() => {
    if (!productViews.includes(view)) {
      prevViewRef.current = view
    }
  }, [view])

  useEffect(() => {
    setListingId(listing_id)
  }, [listing_id])



  const handleComponentsChange = (newComponents) => {
    if (!kc) { return }
    else if (skipKCUpdate.current) {
      skipKCUpdate.current = false
      return
    }
    setKc({ ...kc, components: newComponents })
  }

  function handleSetView(v, new_components = null) {
    setIsLoading(v === 'loading')
    if (new_components) {
      new_components = { ...components || {}, ...new_components }
      handleComponentsChange(new_components)
    } else {
      new_components = components
    }
    if (v === 'id_upload' && !!new_components.id_upload?.redirect_url) {
      window.location.href = new_components.id_upload.redirect_url
    } else if (productViews.includes(v)) {
      return
    } else {
      setView(v)
    }
  }

  function resetData(data) {
    const guest_language = data.components?.guest?.language
    if (!!guest_language) { i18n.changeLanguage(guest_language) }
    let metadata = data.components.metadata
    document.body.style.background = metadata.color2;
    document.querySelector('meta[name="theme-color"]').setAttribute('content', metadata.color2);
    window.title = `Boarding Pass - ${data.components.metadata.name}`
    setTheme(getTheme(metadata))
    handleSetView(data.view, { ...data.components, view: data.view })
  }

  function loadKeycard(auth_key = auth) {
    const flag = !!flags.enableKeycardProductApi;

    const keycardPromise = loadData(auth_key, listing_id, kc?.booking_id ?? null);
    // empty promise
    let productPromise = new Promise((resolve, reject) => resolve({ data: {} }));
    if (flag === true) {
      productPromise = loadProductData(auth_key, listing_id, kc?.booking_id ?? null);
    }

    keycardPromise.then(r => {
      setKc(r.data)
      resetData(r.data)
    }).catch(e => {
      console.log("ERROR GETTING KEYCARD", e)
      handleSetView('error')
    })

    if (flag === true) {
      productPromise.then(async r => {
        const keycardData = await keycardPromise;
        const updated_components = { ...(keycardData.data.components || {}), ...r.data };
        setKc({ ...keycardData.data, components: updated_components })
      }).catch(e => {
        console.log("ERROR GETTING PRODUCT DATA", e);
      });
    }
  }


  useEffect(() => {
    const fullURL = `${location.pathname}${location.search}`
    const re = /^.+[\w-/]$/ // Matches trailing dashes, underscores, slashes & alphanumeric characters
    if (!re.test(fullURL) && fullURL !== "/") {
      const validURL = fullURL.replace(/[^\w-/]*$/, "") // Replace trailing special characters
      console.log("INVALID URL, redirecting...")
      window.location.href = validURL
    } else if (components === null && !!kc?.components) {
      resetData(kc)
    } else if (components === null) {
      loadKeycard()
    }
  }, [kc, location])


  function handleVerify(verify_args) {
    handleSetView('loading')
    verifyPost(auth, kc.booking_id, listing_id, verify_args, view).then(r => {
      if (r.data.success) {
        if (r.data.auth) {
          setAuth(r.data.auth)
        }
        let view_complete = verify_args.hasOwnProperty('view_complete') ? verify_args.view_complete : true
        skipKCUpdate.current = true
        setKc({
          booking_id: r.data.booking_id,
          components: { ...r.data.components },
          view: r.data.view
        })
        handleSetView(
          r.data.view,
          {
            ...r.data.components,
            view: (r.data.view === 'verified') ? 'verified' : 'verify',
            completed_verification: { ...components.completed_verification, [view]: view_complete }
          }
        )
      } else {
        handleSetView(view)
      }
    })
  }


  function verifyField(field, val) {
    if (field == 'guest_email') {
      return String(val).toLowerCase().match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    } else if (field === 'guest_address') {
      return isAddressValid(val)
    } else if (field === 'guest_phone') {
      return val?.length > 7
    } else if (field === 'guest_dob') {
      return !!val && isValidAge(val)
    }
    let result = !!(val || '')

    return (result.hasOwnProperty("formatted") ? result.formatted.trim() : result)
  }


  function handleSubmitDetails(enableBtn = () => { }) {
    let fields = Object.assign([], components.details);
    let success = true;
    for (var i = 0; i < fields.length; i++) {
      if (typeof fields[i].value === 'string') {
        fields[i].value = fields[i].value.trim()
      }
      if (fields[i].required && !verifyField(fields[i].field, fields[i].value)) {
        success = false
        fields[i].error = true
      }
    }
    handleComponentsChange({ ...components, details: fields })
    if (success) { handleVerify({ fields: fields }) }
    else { enableBtn() }
  }


  function handleSwitchBoardingPass(booking_id) {
    handleSetView('loading')
    setKc({ booking_id })
  }


  function renderPage() {
    switch (view) {
      case 'loading':
        return <LoadingScreen />
      case 'details':
        return <CollectDetailsPage
          setView={handleSetView}
          userCountryCode={userCountryCode}
          setUserCountryCode={setUserCountryCode}
          setDetails={d => handleComponentsChange({ ...components, details: d })}
          submitDetails={handleSubmitDetails}
        />
      case 'payment_info':
        return <div>

        </div>
      case 'id_upload':
        if (!components?.id_upload) {
          handleSetView('verify')
        }

        return <CustomIDScanner
          setComponents={newComponents => handleComponentsChange(newComponents)}
          setView={handleSetView}
          handleVerify={handleVerify}
        />
      case 'agreement':
        return <AgreementPage
          setView={handleSetView}
          handleVerify={handleVerify}
        />
      case 'start':
        return <StartPage
          setView={handleSetView}
        />
      case 'start_with_info':
        return <VerifiedPage
          setView={handleSetView}
          handleSwitchBoardingPass={handleSwitchBoardingPass}
        />
      case 'verify':
        return <Verify
          setView={handleSetView}
        />
      case 'verified':
        return <VerifiedPage
          setView={handleSetView}
          handleSwitchBoardingPass={handleSwitchBoardingPass}
        />
      case 'checked_out':
        return <VerifiedPage
          setView={handleSetView}
          handleSwitchBoardingPass={handleSwitchBoardingPass}
        />
      case 'listing_disabled':
        return <VerifiedPage
          setView={handleSetView}
          handleSwitchBoardingPass={handleSwitchBoardingPass}
        />
      case 'revoked':
        return <VerifiedPage
          setView={handleSetView}
          handleSwitchBoardingPass={handleSwitchBoardingPass}
        />
      case 'error':
        return <ErrorPage />
      case 'checked_in':
        return <VerifiedPage
          handleSwitchBoardingPass={handleSwitchBoardingPass}
          setView={v => handleSetView(v)}
        />
      case 'verify_listing_view':
        return <VerifiedPage
          handleSwitchBoardingPass={handleSwitchBoardingPass}
          setView={v => handleSetView(v)}
        />
      case 'feedback':
        return <FeedbackPage
          setView={handleSetView}
        />
    }
  }


  return (<div className={[classes.mainContainer, didMount ? "" : "hide"].join(" ")}>
    {renderPage()}
  </div>)
};

export default withLDConsumer()(AppPages)
