import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { isIOS, isMacOs, isMobile } from 'react-device-detect'
import { AppContext } from 'App'
import { useTranslation } from 'react-i18next'
// UI
import { makeStyles } from '@mui/styles'
import { Box, IconButton, Modal, SvgIcon, Typography } from '@mui/material'
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu'
import { ReactComponent as LeftArrowIcon } from 'assets/icons/chevron-left.svg'
import { ReactComponent as RightArrowIcon } from 'assets/icons/chevron-right.svg'
// Custom
import VerticalCard from 'components/Cards/VerticalCard'
import SimpleCard from 'components/Cards/SimpleCard'
import RowCard from 'components/Cards/RowCard'
import ProductPage from 'components/ProductPage'
import StripePage from 'components/StripePage'

const previewItems = 3

const useStyles = makeStyles(theme => ({
  container: { margin: props => !!props.preview ? theme.spacing(0, -2.5) : 0 },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  iconBtn: {
    position: 'absolute !important',
    top: '50%',
    zIndex: 2,
    backgroundColor: `${theme.palette.primary.main + 'a6'} !important`,
    transform: 'translate(0,-50%)',
    '&.left': { left: '5px !important' },
    '&.right': { right: '5px !important' },
    '&.disabled': { visibility: 'hidden !important' },
  },
  arrowIcon: { color: theme.palette.primary.contrast },
  scrollWrapper: { position: 'relative' },
}))

function Arrow({ left = false, disabled, onClick }) {
  const classes = useStyles()

  if (isMobile) { return null }
  return (<IconButton
    disabled={disabled}
    className={[classes.iconBtn, left ? 'left' : 'right', disabled ? 'disabled' : ''].join(" ")}
    onClick={onClick}
  >
    <SvgIcon
      viewBox='0 0 24 24'
      className={classes.arrowIcon}
      component={!!left ? LeftArrowIcon : RightArrowIcon}
    />
  </IconButton>)
}

function LeftArrow() {
  const { isFirstItemVisible, initComplete, scrollPrev } = useContext(VisibilityContext)
  return <Arrow left disabled={!initComplete || (initComplete && isFirstItemVisible)} onClick={() => scrollPrev()} />
}

function RightArrow() {
  const { isLastItemVisible, initComplete, scrollNext } = useContext(VisibilityContext)
  return <Arrow disabled={!initComplete || (initComplete && isLastItemVisible)} onClick={() => scrollNext()} />
}


const UpgradesGrid = ({
  preview = false, upsellsRef
}) => {
  const classes = useStyles({ preview })
  const { kc, auth, setView, setHideNavbar } = useContext(AppContext)
  const navigate = useNavigate()
  const location = useLocation()
  const { listing_id, upgrade_id } = useParams()
  const { t } = useTranslation()
  const [upsells, setUpsells] = useState([])
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [productLink, setProductLink] = useState(null)
  const [stripeAccount, setStripeAccount] = useState(null)
  let components = kc?.components ?? {}

  useEffect(() => {
    if (!!upgrade_id && !selectedProduct) {
      let newProduct = components.upsells?.find(up => up.product_id === upgrade_id)
      if (!!newProduct) {
        setSelectedProduct(newProduct)
        setHideNavbar(true)
      }
    } else if (upgrade_id == null && !!selectedProduct) {
      handleCloseProduct(true)
    }
  }, [upgrade_id])

  useEffect(() => {
    if (!kc) { return }
    const newUpsells = []
    let data = components.upsells || []
    data.forEach(up => {
      if (up.url === 'payment_link') { newUpsells.push(up) }
    })
    setUpsells(prev => newUpsells.filter(nls => !nls.purchased))
  }, [kc])


  function openUrl(product) {
    if (product.url.startsWith('sms')) {
      if (isIOS || isMacOs) {
        setProductLink(prev => product.url)
      } else {
        setProductLink(prev => product.url.replace('&', '?'))
      }
    } else if (product.url === 'payment_link') {
      let upgradesPath = location.pathname.includes('/upgrades') ? '/upgrades' : '/upgrade'
      let path = !!listing_id
        ? `/${listing_id}${upgradesPath}/${product.product_id}`
        : `${upgradesPath}/${product.product_id}/?auth=${auth}`
      navigate(path)
    } else {
      setProductLink(prev => product.url)
    }
  }

  const goToUpsells = () => {
    let path = !!listing_id
      ? `/${listing_id}/upgrades`
      : `/upgrades/?auth=${auth}`
    navigate(path, { state: { section: "upsells" } })
  }

  const handleCloseProduct = (replace) => {
    setSelectedProduct(prev => null)
    setHideNavbar(false)
    setView(kc.view)
    let upgradesPath = location.pathname.includes('/upgrades') ? '/upgrades' : ''
    let path = !!listing_id
      ? `/${listing_id}${upgradesPath}`
      : `${upgradesPath}/?auth=${auth}`
    navigate(path, { replace: !!replace })
  }


  if (!upsells.length) { return null }
  return (<div className={classes.container}>
    {(!!selectedProduct || !!productLink) && (
      <Modal open>
        <div>
          {!!productLink
            ? <StripePage
              link={productLink}
              stripeAccount={stripeAccount}
              onClose={() => setProductLink(prev => null)}
            />
            : <ProductPage
              product_id={selectedProduct.product_id}
              setStripeAccount={setStripeAccount}
              onPaymentRedirect={(link) => setProductLink(prev => link)}
              onClose={handleCloseProduct}
            />
          }
        </div>
      </Modal>
    )}
    <Box py={1.5} px={2.5} ref={upsellsRef}>
      <Typography variant='body2'>{t("customize_stay").toUpperCase()}</Typography>
    </Box>
    <ScrollMenu
      LeftArrow={isMobile ? undefined : LeftArrow}
      RightArrow={isMobile ? undefined : RightArrow}
      wrapperClassName={classes.scrollWrapper}
    >
      {upsells.map((up, i) => {
        if (i >= previewItems) { return null }
        return (<VerticalCard
          key={up.product_id}
          itemId={up.product_id}
          resource={up}
          firstItem={i === 0}
          lastItem={i === upsells.length - 1 || i === previewItems - 1}
          onClick={openUrl}
        />)
      })}
    </ScrollMenu>
    {(!preview && upsells.length > previewItems) && (<Box>
      <Box py={1.5} px={2.5}>
        <Typography variant='body2'>{t("all_upgrades").toUpperCase()}</Typography>
      </Box>
      <div className={classes.list}>
        {upsells.map((up, i) => {
          if (i < previewItems) { return null }
          return (<RowCard
            key={up.product_id}
            resource={up}
            onClick={openUrl}
          />)
        })}
      </div>
    </Box>)}
    {!!preview && (
      <Box mt={1.5} px={2.5}>
        <SimpleCard
          disableGutters
          onClick={goToUpsells}
          label={`${t("view")} ${(upsells.length - previewItems) > 0
            ? ((upsells.length - previewItems) + ' ')
            : ''}${t("more")}`}
        />
      </Box>
    )}
  </div>)
}

export default UpgradesGrid
