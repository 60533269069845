import React, { useContext, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Button,
  Typography,
  Link,
  TextField,
  Box,
} from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { feedbackPost, uploadImage } from 'api';
import CloseIcon from 'assets/CloseIcon.jsx';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'App';



const useStyles = makeStyles(theme => ({
  logo: {
    height: 50,
    marginBottom: 30,
  },
  container: {
    padding: theme.spacing(0, 2.5),
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(5),
    gap: theme.spacing(2),
  },
}));



export default function FeedbackPage({ setView }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { kc, auth } = useContext(AppContext)
  const [feedback, setFeedback] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)
  const [images, setImages] = useState([])
  let components = kc?.components || {}
  let selectedBooking = kc?.booking_id


  async function submitFeedback() {
    setLoading(true)
    let urls = []
    for (let i = 0; i < images.length; i++) {
      urls.push(await uploadImage(images[i]))
    }
    feedbackPost(auth, selectedBooking, feedback, urls).then(r => {
      setImages([])
      setFeedback('')
      setSubmitted(true)
    })
  }

  const handleCapture = ({ target }) => {
    const reader = new FileReader();
    const val = reader.readAsDataURL(target.files[0])
    reader.onload = function (e) {
      setImages([...images, e.target.result])
    };
  };

  function removeImage(ind) {
    setImages(images.filter((f, i) => i !== ind))
  }


  return <main>
    <div className={classes.container}>
      <Button
        style={{ padding: 0, width: 'fit-content' }}
        onClick={() => { setView(components.view) }}
        startIcon={<ArrowBackIosNewIcon />}
      >
        {t("back")}
      </Button>
      <Box mt={1}>
        <Typography variant='h3'>{t("describe_issue")}</Typography>
      </Box>
      <TextField
        id={`feedback`}
        disabled={loading}
        focused multiline minRows={5}
        fullWidth label={t("issue_or_feedback")} value={feedback}
        onChange={e => setFeedback(e.target.value)}
      />
      {images.map((i, ind) => <div style={{ position: 'relative', display: 'inline-block', marginTop: 10, marginBottom: 10, marginRight: 20 }}>
        <CloseIcon onClick={() => removeImage(ind)} style={{ position: 'absolute', right: -10, top: -10, zIndex: 1000 }} />
        <img src={i} style={{ height: 100 }} />
      </div>)}
      <Button
        disabled={loading}
        style={{ borderRadius: '20px' }} size='large'
        component="label" variant='contained' fullWidth
      >
        {t("add_take_photo")}
        <input
          accept='image/*'
          type="file"
          hidden
          onChange={handleCapture}
        />
      </Button>
      {submitted ?
        <div style={{ marginBottom: 10 }}>
          <Typography>{t("respond_shortly")}
            {' '}<Link onClick={() => { setView(components.view) }}>{t("back")}</Link>
          </Typography>
        </div>
        : <div />
      }
      <Box mt={3}>
        <Button
          id="submitButton" style={{ borderRadius: '12px', fontSize: '22px', padding: 10, marginBottom: 20 }}
          onClick={() => submitFeedback()} fullWidth size="large" variant="contained"
          disabled={!Boolean(feedback) || loading}
        >
          {submitted ? t("submitted") : loading ? `${t("loading")}...` : t("submit")}
        </Button>
      </Box>
    </div>
    <img className={classes.logo} src={components?.metadata?.logo} />
  </main>
}