import React from 'react'
import { useTranslation } from 'react-i18next'
// Utils
import WarningIcon from 'assets/WarningIcon.jsx'
import { isValid } from 'date-fns'
// MUI
import {
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material"
import { makeStyles } from '@mui/styles'
import DatePicker from '@mui/lab/DatePicker'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
// Components
import PhoneComponent from "components/PhoneComponent"
import LanguageSelector from 'components/LanguageSelector'
import AddressAutocomplete from 'components/AddressAutocomplete'
import MultiOptionSelector from './MultiOptionSelector'
import UploadImageField from './UploadImageField'
import { isValidDate } from 'utils'
// Consts
const minDate = new Date("1/1/1910")

const useStyles = makeStyles(theme => ({
  checkbox: { color: theme.palette.primary.main },
  timePicker: {
    "& > label": {
      padding: theme.spacing(0, 1),
      backgroundColor: theme.palette.primary.contrast,
    },
    "& fieldset": {
      border: "2px solid",
      borderColor: theme.palette.primary.main,
      borderRadius: 12,
    },
  },
}))


export default function CollectDetailsInput({ field, index, phoneProps, components, setDetails, imagesToUpload, setImages }) {
  const { phone, initialPhoneCode, initialCountry, setPhone } = phoneProps
  const classes = useStyles()
  const { t } = useTranslation()

  function handleChangeValue(i, value, isDate, hasErrors = null) {
    let fields = Object.assign([], components.details);
    fields[i].value = isDate ? value?.toLocaleTimeString() : value
    fields[i].error = hasErrors !== null
      ? (hasErrors && !!fields[i].required)
      : isDate
        ? !isValid(value) && fields[i].required
        : Array.isArray(value) ? !value.length : false
    setDetails(fields)
  }

  const onPhoneChange = (i, newPhone) => {
    setPhone(newPhone)
    const fields = Object.assign([], components.details)
    fields[i].value = `+${newPhone}`
    fields[i].error = false
    setDetails(fields)
  }

  const handleImageChange = (i, newfile) => {
    let newImages = { ...imagesToUpload, [index]: newfile }
    const fields = Object.assign([], components.details)
    fields[i].error = false
    setImages(newImages)
  }

  const getFieldTranslation = () => {
    switch (field.field) {
      case 'guest_travel_reason': return t("travel_reason")
      case 'guest_first_name': return t("first_name")
      case 'guest_last_name': return t("last_name")
      case 'guest_dob': return t("date_of_birth")
      case 'num_pets': return t("num_of_pets")
      case 'guest_email': return t("email")
      case 'checkin_time': return t("checkin_time")
      case 'checkout_time': return t("checkout_time")

      default: return field.name
    }
  }

  function getFieldByType() {
    switch (field.field_type) {
      case "image": return (
        <UploadImageField
          field={field}
          index={index}
          onChange={(newFile) => handleImageChange(index, newFile)}
        />
      )
      case "checkbox": return (
        <FormControlLabel
          label={getFieldTranslation()}
          control={<Checkbox
            className={classes.checkbox}
            checked={field.value}
            onChange={(e) => handleChangeValue(index, e.target.checked)}
          />}
        />
      )
      case "multi_select":
      case "single_select":
        return <MultiOptionSelector
          field={field}
          index={index}
          onChange={handleChangeValue}
          singleSelection={field.field_type === 'single_select'}
        />
      case "number":
        let numberLabel = getFieldTranslation()
        return <TextField
          id={`detail${index}`}
          focused required={field.required}
          helperText={field.error && <><WarningIcon style={{ marginBottom: -12 }} />{numberLabel} {t("is_required")}</>}
          error={field.error}
          type="number"
          inputProps={{ inputMode: 'numeric', min: 0, max: field.field === 'num_pets' ? 20 : 1000 }}
          fullWidth label={numberLabel}
          onChange={e => handleChangeValue(index, e.target.value)}
        />
      case "date":
        let dateVal = typeof field.value === 'string' ? new Date(field.value) : field.value
        let dateLabel = getFieldTranslation()
        return (<LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label={dateLabel}
            value={dateVal || null}
            minDate={minDate}
            onChange={e => handleChangeValue(index, e?.toDateString())}
            renderInput={(params) =>
              <TextField {...params}
                id={`detail${index}`}
                focused fullWidth error={field.error}
                required={field.required}
                helperText={field.error && <>
                  <WarningIcon style={{ marginBottom: -12 }} />
                  {dateLabel} {!isValidDate(dateVal) ? t("is_required") : t("date_out_of_range")}
                </>}
              />
            }
          />
        </LocalizationProvider>)
      case "time":
        let timeLabel = getFieldTranslation()
        return (
          <TextField
            focused
            fullWidth
            id={`detail${index}`}
            label={timeLabel}
            type="time"
            variant="outlined"
            value={field.value}
            className={classes.timePicker}
            InputLabelProps={{shrink: true}}
            inputProps={{step: 60}}
            error={field.error}
            required={field.required}
            helperText={field.error && <><WarningIcon style={{ marginBottom: -12 }} />
              {field.name} {!field.value ? t("is_required") : "wrong format"}
            </>}
            onChange={(e) => {
              let newTime = e.target.value
              handleChangeValue(index, newTime);
            }}
          />
        )

      case "text":
      default: return (
        <TextField
          id={`detail${index}`}
          focused required={field.required}
          helperText={field.error && <><WarningIcon style={{ marginBottom: -12 }} />{getFieldTranslation()} {t("is_required")}</>}
          error={field.error}
          fullWidth label={getFieldTranslation()} value={field.value}
          onChange={e => handleChangeValue(index, e.target.value)}
        />
      )
    }
  }

  function getField() {
    switch (field.field) {
      case 'guest_language':
        return <LanguageSelector f={field} ind={index} onChange={handleChangeValue} />
      case 'guest_address':
        return <AddressAutocomplete
          address={field.value || {}}
          onChange={(e, hasErrors) => handleChangeValue(index, e, false, hasErrors)}
          textFieldProps={{
            id: `detail${index}`,
            focused: true,
            required: field.required,
            helperText: field.error && <><WarningIcon style={{ marginBottom: -12 }} />{t("address_error")}</>,
            error: field.error,
            fullWidth: true,
            label: t("address")
          }}
        />
      case "guest_phone":
        return <PhoneComponent
          phone={phone}
          country={initialCountry}
          required={field.required}
          initialCode={initialPhoneCode}
          error={field.error}
          label={t("phone_number")}
          onChange={(val) => onPhoneChange(index, val)}
        />

      default: return getFieldByType()
    }
  }


  return getField()
}
