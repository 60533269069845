import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { isIOS, isMacOs } from 'react-device-detect'
import { AppContext } from 'App'
import { useTranslation } from 'react-i18next'
// UI
import { makeStyles } from '@mui/styles'
import { Box, Typography } from '@mui/material'
// Custom
import ContainedCard from 'components/Cards/ContainedCard'
import SimpleCard from 'components/Cards/SimpleCard'

const previewItems = 4

const useStyles = makeStyles(theme => ({
  container: {
    padding: props => !!props.preview ? 0 : theme.spacing(0, 2.5),
  },
  line: {
    height: 1,
    border: 'none',
    opacity: '10%',
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    marginBottom: theme.spacing(3),
  },
}))


const LocalServicesGrid = ({ preview = false, divider }) => {
  const classes = useStyles({ preview })
  const { kc, auth } = useContext(AppContext)
  const navigate = useNavigate()
  const { listing_id } = useParams()
  const { t } = useTranslation()
  const [localServices, setLocalServices] = useState([])
  let components = kc?.components ?? {}

  useEffect(() => {
    if (!kc) { return }
    const newLocalServices = []
    let data = components.upsells || []
    data.forEach(up => {
      if (up.url !== 'payment_link') { newLocalServices.push(up) }
    })
    setLocalServices(prev => newLocalServices.filter(nls => !nls.purchased))
  }, [kc])


  function openUrl(product) {
    if (product.url.startsWith('sms')) {
      if (isIOS || isMacOs) {
        window.location.href = product.url
      } else {
        window.location.href = product.url.replace('&', '?')
      }
    } else {
      window.open(product.url)
    }
  }

  const goToUpsells = () => {
    let path = !!listing_id
      ? `/${listing_id}/upgrades`
      : `/upgrades/?auth=${auth}`
    navigate(path, { state: { section: "local_services" } })
  }


  if (!localServices.length) { return null }
  return (<div className={classes.container}>
    {!!divider && <hr className={classes.line} />}
    <Typography variant='body2'>{t("customize_experience").toUpperCase()}</Typography>
    <Box mt={1.5} display='flex' gap={1.5} flexWrap={'wrap'}>
      {localServices.map((v, i) => {
        if (!!preview && i >= previewItems) { return null }
        return (<ContainedCard
          key={v.product_id}
          upsell={v}
          openUrl={openUrl}
        />)
      })}
    </Box>
    {!!preview && (
      <Box mt={1.5}>
        <SimpleCard
          disableGutters
          onClick={goToUpsells}
          label={`${t("view")} ${(localServices.length - previewItems) > 0
            ? ((localServices.length - previewItems) + ' ')
            : ''}${t("more")}`}
        />
      </Box>
    )}
  </div>)
}

export default LocalServicesGrid
