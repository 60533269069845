import React from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  lighten
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import WarningIcon from 'assets/WarningIcon.jsx';

const useStyles = makeStyles(theme => ({
  container: {
    border: '2px solid !important',
    borderColor: props => props.hasErrors
      ? theme.palette.error.main + '!important' 
      : theme.palette.primary.main + '!important',
    borderRadius: 12,
  },
  label: {
    width: 'fit-content',
    backgroundColor: theme.palette.primary.contrast,
    zIndex: 1,
    padding: theme.spacing(0, 0.5),
    transform: 'translate(10px, -11px)',
    color: props => props.hasErrors
      ? theme.palette.error.main
      : theme.palette.primary.main,
  },
  divider: { backgroundColor: theme.palette.primary.dark },
  required: {
    paddingLeft: 3,
    fontSize: 15,
  },
  formControlLabel: {
    margin: '0px !important',
    borderRadius: 10,
    backgroundColor: lighten(theme.palette.primary.contrast, 0.15),
  },
  controlGroup: {
    gap: 8,
    padding: '0 12px 16px',
  },
  controlBtn: {
    color: theme.palette.primary.main + '!important',
  },
  caption: {
    margin: '-3px 14px 0px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}))


export default function MultiOptionSelector({ field, index, onChange, singleSelection }) {
  const hasErrors = !!field.error && !!field.required
  const { t } = useTranslation()
  const classes = useStyles({ hasErrors })
  let options = field.field_options

  const handleCheckboxChange = (opt) => (e) => {
    let checked = e.target.checked
    if (!!field.value) {
      if (checked) { onChange(index, field.value.concat(opt)) }
      else { onChange(index, field.value.filter(f => f !== opt)) }
    } else if (!!checked) { onChange(index, [opt]) }
  }

  return (<>
    <FormControl fullWidth className={classes.container}>
      <FormLabel className={classes.label} id={`multi-selector-${field.field}`}>
        {field.name}{!!field.required ? <span className={classes.required}>{'*'}</span> : ''}
      </FormLabel>
      {singleSelection
        ? <RadioGroup
          aria-labelledby={`multi-selector-${field.field}`}
          name={`multi-selector-options-group`}
          value={field.value}
          className={classes.controlGroup}
          onChange={e => onChange(index, e.target.value)}
        >
          {options.map(opt => (<FormControlLabel
            key={opt}
            value={opt}
            className={classes.formControlLabel}
            control={<Radio className={classes.controlBtn} />}
            label={opt}
          />))}
        </RadioGroup>
        : <FormGroup className={classes.controlGroup}>
          {options.map(opt => (<FormControlLabel
            key={opt}
            value={opt}
            className={classes.formControlLabel}
            control={<Checkbox
              checked={field.value.includes(opt)}
              onChange={handleCheckboxChange(opt)}
              className={classes.controlBtn}
            />}
            label={opt}
          />))}
        </FormGroup>
      }
    </FormControl>
    {hasErrors && (<div className={classes.caption}>
      <WarningIcon color='error' style={{ marginTop: 8 }} />
      <Typography variant='caption' color={'error'}>{`${field.name} is required`}</Typography>
    </div>)}
  </>);
};
