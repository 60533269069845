import SvgIcon from '@mui/material/SvgIcon';

export default function HomeIcon(props) {
  return (<SvgIcon  viewBox="0 0 30 30" {...props}>
			<path d="M25.63,22.49a1.24,1.24,0,0,0-1.25,1.25v1.87a.65.65,0,0,1-.63.63H18.13a1.25,1.25,0,0,0,0,2.5h5.62A3.16,3.16,0,0,0,26,27.82a3.1,3.1,0,0,0,.92-2.21V23.74A1.25,1.25,0,0,0,25.63,22.49Z"/>
			<path d="M18.13,6.24h5.62a.59.59,0,0,1,.44.18.63.63,0,0,1,.19.44V8.74a1.25,1.25,0,0,0,2.5,0V6.86A3.12,3.12,0,0,0,26,4.65a3.15,3.15,0,0,0-2.21-.91H18.13a1.25,1.25,0,0,0,0,2.5Z"/>
			<path d="M14.82.49A2.22,2.22,0,0,0,12.94.05L1.73,2.82A2.19,2.19,0,0,0,.5,3.57,2.22,2.22,0,0,0,0,5V26.81a2.2,2.2,0,0,0,.53,1.43A2.22,2.22,0,0,0,1.88,29l11,1.57a1.32,1.32,0,0,0,.44.08h.14A2.21,2.21,0,0,0,15,30a2.18,2.18,0,0,0,.65-1.55V2.18A2.26,2.26,0,0,0,14.82.49ZM13.13,28.06,2.5,26.54V5.2L13.13,2.58Z"/>
			<path d="M18.12,15.42a1.12,1.12,0,0,0-.19.29.88.88,0,0,0-.05.15,1.36,1.36,0,0,0-.06.29s0,.08,0,.13a1.32,1.32,0,0,0,.09.43,1.12,1.12,0,0,0,.26.38s0,0,0,0l3.75,3.75a1.25,1.25,0,0,0,1.77-1.76l-1.61-1.61h7.29a1.25,1.25,0,1,0,0-2.5h-7.3l1.62-1.62a1.25,1.25,0,0,0,0-1.77,1.28,1.28,0,0,0-.89-.36,1.24,1.24,0,0,0-.88.36l-3.73,3.73,0,0Z"/>\
			<path d="M8.92,16.5A1.72,1.72,0,0,0,9.8,17l.35,0a1.57,1.57,0,0,0,.65-.13,1.66,1.66,0,0,0,.78-.63,1.73,1.73,0,0,0,.3-1,1.67,1.67,0,0,0-.51-1.21,1.75,1.75,0,0,0-.95-.49l-.24,0a1.84,1.84,0,0,0-1,.27,1.73,1.73,0,0,0-.65.77,1.72,1.72,0,0,0,.35,1.88Z"/>
	</SvgIcon>
	)
}

