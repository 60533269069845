import { useEffect, useRef } from 'react';
import ImageCompressor from 'js-image-compressor';
import { asyncLoop } from 'helpers';
const maxCompressionImageSize = 2000000 //bytes

export const useImageCompressor = ({ srcImgs = [], onSuccess = () => null, fileInObject = false }) => {
  const compressedImgs = useRef([])

  useEffect(() => {
    if (!srcImgs.length) { return }
    async function compressImages(images) {
      let compressionNeeded = false
      const filesToCompress = images.map((f, i) => ({
        fileData: fileInObject ? { ...f } : f,
        compressData: {
          file: fileInObject ? f.file : f,
          redressOrientation: false,
          quality: Number((maxCompressionImageSize / (fileInObject ? f.file.size : f.size || maxCompressionImageSize)).toFixed(2)),
          success: (result) => compressSuccess(f, result, i === filesToCompress.length - 1),
          error: (msg) => compressError(msg)
        }
      }))

      await asyncLoop(filesToCompress, async (cf) => {
        if (cf.compressData.quality >= 1) {
          compressedImgs.current.push(cf.fileData)
        } else {
          compressionNeeded = true
          await new ImageCompressor(cf.compressData)
        }
      })
      if (!compressionNeeded) {
        onSuccess(compressedImgs.current)
      }
    }

    compressedImgs.current = []
    compressImages(srcImgs)
  }, [srcImgs])


  const compressSuccess = (f, result, isLastImg) => {
    ImageCompressor.file2DataUrl(result, (url) => {
      compressedImgs.current.push(fileInObject ? { ...f, file: result, picture: url } : result)
      if (isLastImg) {
        onSuccess(compressedImgs.current)
      }
    })
  }

  const compressError = (msg) => {
    console.log("Failed to compress: ", msg)
  }

  return compressedImgs.current
}
