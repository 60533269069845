import React, { useContext, useEffect, useRef, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { isIOS, isMacOs } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { AppContext, allowedViewsToShowInfo } from 'App'
import { loadData } from 'api'
import i18next from 'i18next'
import getTheme from 'theme'
// UI
import { makeStyles } from '@mui/styles'
import { Box, ButtonBase, Button } from '@mui/material'
import { AppleWalletIcon } from 'assets/Icons.jsx'
// Custom
import Maps from 'components/Maps.jsx';
import LoadingScreen from 'components/Loading'
import SectionHeaderCard from 'components/Cards/SectionHeaderCard'
import BrandingFooter from 'components/BrandingFooter'
import SimpleCard from 'components/Cards/SimpleCard'
import GuidebooksGrid from 'components/Grids/GuidebooksGrid'
import MapsToggleButton from 'components/MapsToggleButton'
import { getAppleWallet } from 'helpers'

const defaultBGImg = "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Inside+Local+Resturant.webp"

const useStyles = makeStyles(theme => ({
  container: {
    flex: 1,
    width: '-webkit-fill-available',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  content: {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
    maxWidth: 800,
  },
}))


const places = [
  { id: 1, name: 'Restaurant 1', lat: 51.505, lng: -0.09, iconUrl: 'https://example.com/restaurant-icon.png' },
  { id: 2, name: 'Attraction 1', lat: 51.51, lng: -0.1, iconUrl: 'https://example.com/attraction-icon.png' },
  { id: 3, name: 'Store 1', lat: 51.51, lng: -0.08, iconUrl: 'https://example.com/store-icon.png' },
];

const home = {
  lat: 43.6425662,
  lng: -79.3896317,
  name: 'Home',
  iconUrl: 'https://example.com/home-icon.png',
};

const Guides = ({
  setTheme, isLoading, setIsLoading
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const location = useLocation()
  const { kc, auth, view, setView, setKc } = useContext(AppContext)
  const { listing_id } = useParams()
  const [showMap, setShowMap] = useState(false);
  const guidesRef = useRef(null)
  let isListingBP = !location.search
  let components = kc?.components ?? {}
  let bookingId = kc?.booking_id
  let hasGuides = !!components.guidebooks?.length

  function redirectToHome() {
    window.location.replace(location.pathname.replace("/guides", isListingBP ? "/" : "") + location.search)
  }

  useEffect(() => {
    let timer = null
    timer = setTimeout(() => {
      if (location.state?.section === 'guides' && !!guidesRef.current) {
        guidesRef.current.scrollIntoView({ block: "start", behavior: "smooth" })
      }
    })

    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    if (!kc) {
      setIsLoading(prev => true)
      loadData(auth, listing_id, null).then(r => {
        setKc(r.data)
        const guest_language = r.data.components?.guest?.language
        if (!!guest_language) { i18next.changeLanguage(guest_language) }
        let metadata = r.data.components.metadata
        document.body.style.background = metadata.color2;
        document.querySelector('meta[name="theme-color"]').setAttribute('content', metadata.color2);
        window.title = `Boarding Pass - ${r.data.components.metadata.name}`
        setTheme(getTheme(metadata))
        setView(r.data.view, { ...r.data.components, view: r.data.view })
        setIsLoading(prev => false)
        if (!r.data?.components?.guidebooks?.length || !allowedViewsToShowInfo.includes(r.data.view)) { redirectToHome() }
      }).catch(e => {
        console.log("ERROR GETTING KEYCARD", e)
        setView('error')
        setIsLoading(prev => false)
      })
    } else if (!hasGuides) {
      redirectToHome()
    }
  }, [])


  if (showMap) {
    return <Maps
      handleViewChange={() => setShowMap(false)}
      places={places}
      home={home}
    />
  }
  if (isLoading) { return <LoadingScreen /> }
  else if (!kc || !hasGuides) { return null }
  return (<div className={classes.container}>
    <SectionHeaderCard
      title={t("guides")}
      titleRef={guidesRef}
      img={components.guidebooks?.[0]?.header_image ?? defaultBGImg}
      /*actions={
        <MapsToggleButton view='guides' handleViewChange={() => setShowMap(true)}/>
      }*/
    />
    <div className={classes.content}>
      {!components.guidebooks?.length && (
        <Box mt={5}>
          <SimpleCard label={t("empty_guidebooks")} />
        </Box>
      )}
      <Box mt={5} px={2.5}>
        <GuidebooksGrid />
      </Box>
    </div>
    <Box mt={7.5} display='flex' flexDirection='column' alignItems='center'>
      <BrandingFooter
        metadata={components?.metadata}
        extras={
          view !== 'revoked' && (isMacOs || isIOS) ?
            <ButtonBase
              style={{ marginLeft: 10, marginRight: 10 }}
              onClick={() => getAppleWallet(auth, bookingId, setIsLoading)}
            >
              <AppleWalletIcon />
            </ButtonBase>
            :
            null
        }
      />
    </Box>
  </div>)
}

export default Guides
