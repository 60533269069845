import { downloadAppleWallet } from "api";

export function hexToRgb(hex, opacity = 0.5) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return `rgba(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)},${opacity})`
}

export function gradientRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return `rgba(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)},1)`
}

export function ImageDataToBlob(imageData) {
  console.log("CONVERTING BLOB", imageData)
  let w = imageData.width;
  let h = imageData.height;
  let canvas = document.createElement("canvas");
  canvas.width = w;
  canvas.height = h;
  let ctx = canvas.getContext("2d");
  ctx.putImageData(imageData, 0, 0);        // synchronous
  return new Promise((resolve, reject) => {
    canvas.toBlob(resolve, 'image/jpeg'); // implied image/png format
  })
}

const AdjustedTimeLocale = (datetime) => {
  const options = {
    timeStyle: 'short'
  }
  return new Date(datetime).toLocaleTimeString([], options)
}

const AdjustedDateLocale = (date) => {
  const options = {
    dateStyle: 'medium'
  };
  return new Date(date).toLocaleDateString([], options)
}

export const AdjustedDateTime = (booking_string) => {
  const bstring = booking_string
  const _date = bstring.split("@")[0].trim()
  const _time = bstring.split("@")[1].trim()
  const [d_date, d_time] = nDateTimeHelper(_date, _time)
  return `${d_date} @ ${d_time}`
}

const nDateTimeHelper = (date, time) => {
  const ddate = new Date(date + " " + time.split(":")[0].trim() + ":" + time.split(":")[1].trim())
  return [AdjustedDateLocale(ddate), AdjustedTimeLocale(ddate)]
}

export const nTimeHelper = (time) => {
  const now = new Date()
  const newDate = new Date(new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), time.split(":")[0].trim(), time.split(":")[1].trim())).getTime() + now.getTimezoneOffset() * 60000)
  return AdjustedTimeLocale(newDate)
}

export const getEnsoErrorMessage = error => {
  return error?.response?.data?.error?.[0]?.title
}

export const asyncLoop = async (arr = [], callback) => {
  for (let i = 0; i < arr.length; i++) {
    await callback(arr[i], i, arr)
  }
}

export const isAddressValid = (address, showErrors = false) => {
  if (!address || !address?.lng) { return false }
  let hasCity = !!address.city
  let hasCountry = !!address.country
  let hasStreet = !!address.street
  let hasState = !!address.state
  let hasZipCode = !!address.zip_code
  let isValid = hasCity && hasCountry && hasStreet && hasState && hasZipCode
  if (!showErrors) { return isValid }
  else {
    return {
      valid: isValid,
      errors: {
        city: !hasCity,
        country: !hasCountry,
        street: !hasStreet,
        state: !hasState,
        zip_code: !hasZipCode,
      }
    }
  }
}

export function getAppleWallet(auth, booking_id, setLoading) {
  setLoading(true)
  downloadAppleWallet(auth, booking_id).then(response => {
    const url = window.URL.createObjectURL(new Blob([Buffer.from(response.data, 'base64')], { type: 'application/vnd.apple.pkpass' }))
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', booking_id + "_boardingpass.pkpass");
    document.body.appendChild(link);
    link.click();
    setLoading(false)
  })
}

export function getAbsoluteUrl(url) {
  if (url.startsWith("https://") || url.startsWith("https://")) {
    return url
  } else {
    return `https://${url}`
  }
}

export function getVideoLink(url) {
  if (url.includes("youtube") || url.includes("youtu.be")) {
    let videoUrl = 'https://www.youtube.com/embed/'
    let videoID = ''
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
    const match = url.match(regex)
    videoID = match ? match[1] : null
    return videoUrl + videoID
  } else return url
}
