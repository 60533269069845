import React, { useState } from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import { Typography, Button, Box } from '@mui/material';
import MapsToggleButton from './MapsToggleButton';

const getIcon = (type, color) => {
  switch (type) {
    case 'home':
      return {
        path: 'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S10.62 6.5 12 6.5 14.5 7.62 14.5 9 13.38 11.5 12 11.5z',
        fillColor: color,
        fillOpacity: 1,
        strokeColor: '#000',
        strokeWeight: 1,
        scale: 2,
        anchor: new window.google.maps.Point(12, 24),
      };
    case 'restaurant':
      return {
        path: 'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S10.62 6.5 12 6.5 14.5 7.62 14.5 9 13.38 11.5 12 11.5z',
        fillColor: color,
        fillOpacity: 1,
        strokeColor: '#000',
        strokeWeight: 1,
        scale: 2,
        anchor: new window.google.maps.Point(12, 24),
      };
    case 'attraction':
      return {
        path: 'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S10.62 6.5 12 6.5 14.5 7.62 14.5 9 13.38 11.5 12 11.5z',
        fillColor: color,
        fillOpacity: 1,
        strokeColor: '#000',
        strokeWeight: 1,
        scale: 2,
        anchor: new window.google.maps.Point(12, 24),
      };
    case 'store':
      return {
        path: 'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S10.62 6.5 12 6.5 14.5 7.62 14.5 9 13.38 11.5 12 11.5z',
        fillColor: color,
        fillOpacity: 1,
        strokeColor: '#000',
        strokeWeight: 1,
        scale: 2,
        anchor: new window.google.maps.Point(12, 24),
      };
    default:
      return {
        path: 'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S10.62 6.5 12 6.5 14.5 7.62 14.5 9 13.38 11.5 12 11.5z',
        fillColor: color,
        fillOpacity: 1,
        strokeColor: '#000',
        strokeWeight: 1,
        scale: 2,
        anchor: new window.google.maps.Point(12, 24),
      };
  }
};

const Maps = ({ primaryColor = 'black', backgroundColor = 'white', home, places, handleViewChange }) => {
  const { lat, lng } = home;
  const homePosition = { lat, lng };
  const [selectedPlace, setSelectedPlace] = useState(null);

  const mapContainerStyle = {
    height: '100vh',
    width: '100%',
  };

  const [mapOptions, setMapOptions] = useState(null);

  const handleLoad = () => {
    const options = {
      streetViewControl: false,
      mapTypeControl: false,
      fullscreenControl: false,
      zoomControl: true,
      zoomControlOptions: {
        position: window.google.maps.ControlPosition.LEFT_TOP,
      },
    };
    setMapOptions(options);
  };

  const handleMapLoad = (map) => {
    const zoomControlDiv = map.controls[window.google.maps.ControlPosition.LEFT_TOP].getAt(0);
    if (zoomControlDiv) {
      zoomControlDiv.style.marginTop = '50px'; // Move the zoom in/out buttons up by 50px
    }
  };

  return (
    <div style={{ width: '100%', height: '100%', position: 'fixed' }}>
      <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY} onLoad={handleLoad} loadingElement={<div>Loading...</div>}>
        <MapsToggleButton view="maps" handleViewChange={handleViewChange} />
        {mapOptions && (
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={homePosition}
            zoom={13}
            options={mapOptions}
            onLoad={handleMapLoad}
          >
            <Marker
              position={homePosition}
              icon={getIcon('home', primaryColor)}
              onClick={() => setSelectedPlace(home)}
            >
              {selectedPlace && selectedPlace.lat === lat && selectedPlace.lng === lng && (
                <InfoWindow position={homePosition} onCloseClick={() => setSelectedPlace(null)}>
                  <Box p={2}>
                    <Typography variant="h6" sx={{color: primaryColor}}>{home.name}</Typography>
                    <Button variant="contained" sx={{ mt: 1}}>
                      Get Directions
                    </Button>
                    <Button variant="contained" sx={{ mt: 1 }}>
                      View Check-in Steps
                    </Button>
                  </Box>
                </InfoWindow>
              )}
            </Marker>
            {places.map((place) => (
              <Marker
                key={place.id}
                position={{ lat: place.lat, lng: place.lng }}
                icon={getIcon(place.type, primaryColor)}
                onClick={() => setSelectedPlace(place)}
              >
                {selectedPlace && selectedPlace.id === place.id && (
                  <InfoWindow
                    style={{backgroundColor: primaryColor, color: primaryColor}}
                    position={{ lat: place.lat, lng: place.lng }}
                    onCloseClick={() => setSelectedPlace(null)}
                  >
                    <Box p={2}>
                      <Typography variant="h1">{place.name}</Typography>
                      <Button variant="contained" sx={{ mt: 1 }}>
                        Get Directions
                      </Button>
                      <Button variant="contained" sx={{ mt: 1 }}>
                        Show Guidebooks
                      </Button>
                    </Box>
                  </InfoWindow>
                )}
              </Marker>
            ))}
          </GoogleMap>
        )}
      </LoadScript>
    </div>
  );
};

export default Maps;
