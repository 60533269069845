import { purchaseGet, purchasePost } from "api";
import { getEnsoErrorMessage } from "helpers";

export const getPurchase = async ({
  auth, booking_id, product_id, resource,
  onSuccess = () => { }, onError = () => { }
}) => {
  let params = `?auth=${auth}&booking_id=${booking_id}&product_id=${product_id}&resource=${resource}`

  try {
    const response = await purchaseGet(params)
    onSuccess(response)
  } catch (error) { onError(error) }
}

export const savePurchase = async ({
  body, setAlertOptions = () => { }, onSuccess = () => { }, onError = () => { }
}) => {
  try {
    const response = await purchasePost(body)
    setAlertOptions({ open: true, message: "Purchase Registered", success: true })
    window.location.reload()
    onSuccess(response)
  }
  catch (error) {
    onError(error)
    setAlertOptions({ open: true, message: getEnsoErrorMessage(error) || "Purchase Failed" })
  }
}
