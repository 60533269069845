import React, { useContext, useEffect, useRef } from 'react'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk'
import { isIOS, isMacOs } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { AppContext, allowedViewsToShowInfo } from 'App'
import { loadData, loadProductData } from 'api'
import i18next from 'i18next'
import getTheme from 'theme'
// UI
import { makeStyles } from '@mui/styles'
import { Box, ButtonBase, Typography, IconButton, Badge } from '@mui/material'
import { AppleWalletIcon } from 'assets/Icons.jsx'
import { ConciergeIcon } from 'assets/BrandIcons'
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import { useTheme } from '@mui/material/styles'
// Custom
import LoadingScreen from 'components/Loading'
import SectionHeaderCard from 'components/Cards/SectionHeaderCard'
import BrandingFooter from 'components/BrandingFooter'
import SimpleCard from 'components/Cards/SimpleCard'
import LocalServicesGrid from 'components/Grids/LocalServicesGrid'
import UpgradesGrid from 'components/Grids/UpgradesGrid'
import { getAppleWallet } from 'helpers'

const defaultBGImg = "https://enso-image-assets.s3.amazonaws.com/Stock+Photo+Library/Inside+Local+Resturant.webp"

const useStyles = makeStyles(theme => ({
  container: {
    flex: 1,
    width: '-webkit-fill-available',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  content: {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
    maxWidth: 800,
  },
}))


const Upgrades = ({
  setTheme, isLoading, setIsLoading
}) => {
  const classes = useStyles()
  const flags = useFlags();
  const { t } = useTranslation()
  const location = useLocation()
  let navigate = useNavigate()
  const theme = useTheme()
  const { kc, auth, view, setKc, setView } = useContext(AppContext)
  const { listing_id } = useParams()
  const upsellsRef = useRef(null)
  const localServicesRef = useRef(null)
  let isListingBP = !location.search
  let components = kc?.components ?? {}
  let bookingId = kc?.booking_id
  let hasUpsells = !!components.upsells?.length
  let pendingActions = [...(components?.pending_upsells || []), ...(components?.requires_action_upsell || [])]

  function redirectToHome() {
    window.location.replace(location.pathname.replace("/upgrades", isListingBP ? "/" : "") + location.search)
  }

  useEffect(() => {
    let timer = null
    timer = setTimeout(() => {
      if (location.state?.section === 'upsells' && !!upsellsRef.current) {
        upsellsRef.current.scrollIntoView({ block: "start", behavior: "smooth" })
      } else if (location.state?.section === 'local_services' && !!localServicesRef.current) {
        localServicesRef.current.scrollIntoView({ block: "start", behavior: "smooth" })
      }
    })

    return () => clearTimeout(timer)
  }, [])

  const goToPurchases = () => {
    let path = !!listing_id
      ? `/${listing_id}/purchases`
      : `/purchases/?auth=${auth}`
    navigate(path)
  }

  useEffect(() => {
    if (!kc) {
      setIsLoading(prev => true)
      const flag = !!flags.enableKeycardProductApi;

      const keycardPromise = loadData(auth, listing_id, null);
      // empty promise
      let productPromise = new Promise((resolve, reject) => resolve({ data: {} }));
      if (flag === true) {
        productPromise = loadProductData(auth, listing_id, null);
      }

      keycardPromise.then(r => {
        setKc(r.data)
        const guest_language = r.data.components?.guest?.language
        if (!!guest_language) { i18next.changeLanguage(guest_language) }
        let metadata = r.data.components.metadata
        document.body.style.background = metadata.color2;
        document.querySelector('meta[name="theme-color"]').setAttribute('content', metadata.color2);
        window.title = `Boarding Pass - ${r.data.components.metadata.name}`
        setTheme(getTheme(metadata))
        setView(r.data.view)
        setIsLoading(prev => false)
        if ((!flag && !r.data?.components?.upsells?.length) || !allowedViewsToShowInfo.includes(r.data.view)) { redirectToHome() }
      }).catch(e => {
        console.log("ERROR GETTING KEYCARD", e)
        setView('error')
        setIsLoading(prev => false)
      })

      if (flag === true) {
        productPromise.then(async r => {
          const keycardData = await keycardPromise;
          const updated_components = { ...(keycardData.data.components || {}), ...r.data };
          setKc({ ...keycardData.data, components: updated_components })
        }).catch(e => {
          console.log("ERROR GETTING PRODUCT DATA", e);
          setView('error')
          setIsLoading(prev => false)
        });
      }
    } else if (!hasUpsells) {
      redirectToHome()
    }
  }, [])


  const pendingUpsells = !!pendingActions.length && <Box mt={5} mx={2.5} mb={0}>
    {pendingActions?.map((b, i) =>
      <div key={i} style={{ border: `1px solid ${theme.palette.primary.main}`, borderRadius: 20, padding: 10, display: 'flex', alignItems: 'center' }}>
        <ConciergeIcon sx={{ color: theme.palette.primary.main }} />
        <div style={{ flex: 1, marginLeft: 10, marginRight: 10 }}>
          <Typography>{b.message}</Typography>
        </div>
      </div>
    )}
  </Box>



  if (isLoading) { return <LoadingScreen /> }
  else if (!kc || !hasUpsells) { return null }
  return (<div className={classes.container}>
    <SectionHeaderCard
      title={t("upgrades")}
      img={components.upsells?.[0]?.header_image ?? defaultBGImg}
      actions={!!components?.purchased_upsells?.length &&
        <IconButton style={{ marginRight: 20 }} onClick={goToPurchases}>
          <Badge badgeContent={components?.purchased_upsells?.length} color="primary">
            <ShoppingBasketIcon sx={{ fontSize: 45 }} />
          </Badge>
        </IconButton>
      }
    />
    <div className={classes.content}>
      {pendingUpsells}
      {!components.upsells?.length && (
        <Box mt={5}>
          <SimpleCard label={t("empty_upgrades")} />
        </Box>
      )}
      {/* Upsells */}
      <Box mt={!!pendingActions.length ? 0 : 5}>
        <UpgradesGrid upsellsRef={upsellsRef} />
      </Box>
      {/* Local Services */}
      <Box ref={localServicesRef}>
        <LocalServicesGrid />
      </Box>
    </div>
    {/* Branding footer */}
    <Box mt={7.5} display='flex' flexDirection='column' alignItems='center'>
      <BrandingFooter
        metadata={components?.metadata}
        extras={
          view !== 'revoked' && (isMacOs || isIOS) ?
            <ButtonBase
              style={{ marginLeft: 10, marginRight: 10 }}
              onClick={() => getAppleWallet(auth, bookingId, setIsLoading)}
            >
              <AppleWalletIcon />
            </ButtonBase>
            :
            null
        }
      />
    </Box>
  </div>)
}

export default withLDConsumer()(Upgrades)
