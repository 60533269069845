import React, { useState, useEffect, useContext } from 'react';
import { purchasePost, verifyPost } from 'api.js';
import { useParams, Navigate } from "react-router-dom";
import Loading from 'components/Loading.jsx';
import {
	Typography,
	Button,
	Box
} from '@mui/material'
import { getEnsoErrorMessage } from 'helpers';
import { AppContext } from 'App';

export default function AppRedirect({ setAuth }) {
	const { status, integration } = useParams();
	const { setKc, setView, auth, setAlertOptions } = useContext(AppContext)
	const [loaded, setLoaded] = useState(false)
	const [redirect, setRedirect] = useState(null)
	const [localView, setLocalView] = useState(null)

	useEffect(() => {
		let params = new URLSearchParams(window.location.search)
		let selectedBooking = params.get('booking_id')

		if (!!integration && !loaded && selectedBooking) {
			verifyPost(null, selectedBooking, null, { 'verification_service': integration }, 'id_upload').then(r => {
				if (r?.data?.auth) {
					setAuth(r.data.auth)
				}

				if (!r?.data?.success) {
					setLocalView('error')
					return
				}

				window.location.href = `${window.location.origin}/?auth=${r.data.auth}`
			})
		} else if ((status === 'success') && !loaded) {
			let body = {
				auth: params.get('auth'),
				session_id: params.get('session_id'),
				sku_id: params.get('sku_id'),
				resource: params.get('resource'),
				booking_id: selectedBooking,
			}

			purchasePost(body).then(r => {
				setLoaded(true)
				if (!r.data.success) {
					setLocalView('error')
				} else {
					setRedirect(r.data.view)
					console.log("GOT PURCHASE RESPONSE", r)
				}
			}).catch(error => {
				setAlertOptions({ open: true, message: getEnsoErrorMessage(error) || "Purchase Failed" })
				setRedirect(true)
			})
		}
	}, [redirect])

	if (redirect) {
		return <Navigate to={`/?auth=${auth}`} />
	} else {
		switch (localView) {
			case 'error':
				return <div style={{ margin: 20, marginTop: 40, backgroundColor: 'white' }}>
					<Typography variant='h4'>Whoops! Looks like there was an error processing your payment.</Typography>
					<Typography variant='h6'>If you are unabled to access your purchase, please contact support@ensoconnect.com</Typography>
					<Button onClick={() => setRedirect(true)} variant='contained'>
						Go Back to Boarding Pass
					</Button>
				</div>
			case 'fees':
				return <main>
					<Box mt={5} mx={2.5}>
						<Typography variant="h3" align='center'>Your purchase was successfull</Typography>
					</Box>
				</main>
			default:
				return <Loading />
		}
	}
}