import React, { Fragment, useContext, useRef, useState } from "react";
import {
  Button,
  Typography,
  Link,
  Popover,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import 'react-html5-camera-photo/build/css/index.css';
import { uploadImage } from 'api.js'
import VerifyItem from 'components/VerifyItem.jsx';
import { isDesktop, isMobile } from 'react-device-detect';
import { useTranslation } from "react-i18next";
import CircularProgress from '@mui/material/CircularProgress';
import { AppContext } from "App";
import heic2any from "heic2any";

const useStyles = makeStyles((theme) => ({
  input: {
    display: "none",
  },
  previewImg: {
    width: '100%'
  },
  faceImage: {
    color: theme.palette.primary.light,
  },
  camera_container: {
    border: `3px solid ${theme.palette.primary.main}`,
    borderRadius: 10,
    width: 'fit-content',
    height: '100%',
    position: 'relative',
    '&.disabled': {
      '& #outer-circle': { pointerEvents: 'none' },
    },
  },
  previewUploaded: {
    maxWidth: 800,
    maxHeight: 600,
    objectFit: 'contain',
    width: '100%',
  },
  container: {
    width: '100vw',
    maxWidth: 800,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2.5),
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
}));


const ViewImage = ({ dataUri }) => {
  const classes = useStyles();
  return <img className={classes.previewImg} src={dataUri} />
}

const CameraView = ({
  dataUri, cameraLoading, cameraError, selectedIDType, handleTakePhotoAnimationDone,
  setCameraError, handleCameraStart, openFileExplorer
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return <Fragment>
    {!dataUri && <Typography style={{ marginBottom: 15 }} variant='h3'>{t(`${selectedIDType}`)}</Typography>}
    <div>
      {dataUri ? <ViewImage dataUri={dataUri} /> :
        <div className={[classes.camera_container, (cameraLoading || !!cameraError) ? 'disabled' : ''].join(" ")}>
          <Camera
            onTakePhotoAnimationDone={handleTakePhotoAnimationDone}
            idealFacingMode={(selectedIDType === 'selfie') ? FACING_MODES.USER : FACING_MODES.ENVIRONMENT}
            imageType={IMAGE_TYPES.JPG}
            isImageMirror={false}
            onCameraError={(err) => setCameraError(prev => err)}
            onCameraStart={(stream) => { handleCameraStart(stream) }}
          />
        </div>
      }
    </div>
    {(isMobile && !!cameraError) && (
      <Button
        fullWidth
        size="large"
        variant="contained"
        style={{ borderRadius: 30, marginTop: 20 }}
        onClick={() => openFileExplorer(true)}
      >{t("upload_photo")}</Button>
    )}
  </Fragment>
}


function CustomIDScanner({ handleVerify, setView, setComponents }) {
  const classes = useStyles();
  const inputRef = useRef(null);
  const { t } = useTranslation();
  const { kc } = useContext(AppContext)
  const [cameraOpen, setCameraOpen] = useState(false);
  const [filePreview, setFilePreview] = useState(false);
  const [file, setFile] = useState(null);
  const [selectedIDType, setSelectedIDType] = useState(false);
  const [dataUri, setDataUri] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [cameraLoading, setCameraLoading] = useState(true)
  const [cameraError, setCameraError] = useState(null)
  let components = kc?.components || {}
  let id_upload = components.id_upload


  const uploadFailedText = t("id_upload_fail")

  React.useEffect(() => {
    if (!cameraOpen) { setCameraError(prev => null) }
  }, [cameraOpen])

  const openFileExplorer = () => {
    setAnchorEl(prev => null);
    inputRef.current.value = null;
    inputRef.current.click();
    setSubmitDisabled(false);
  }

  const handleClosePreview = () => {
    setSubmitDisabled(false)
    setFile(prev => null);
    setFilePreview(prev => false);
  };

  const handleCloseCamera = () => {
    setSubmitDisabled(false);
    setCameraOpen(false);
  }

  function handleTakePhotoAnimationDone(dataUri) {
    if (cameraLoading || dataUri.length === 6) { return }
    setDataUri(dataUri);
  }

  const handleCapture = ({ target }) => {
    const file = target.files[0];
    if (file.type === "image/heic" || file.name.endsWith(".heic")) {
      heic2any({ blob: file, toType: "image/jpeg" })
        .then((conversionResult) => {
          const reader = new FileReader();
          reader.onload = function (e) {
            setFile(e.target.result);
            setFilePreview(true);
            if (cameraOpen) {
              setCameraOpen(false);
            }
          };
          reader.readAsDataURL(conversionResult);
        })
        .catch((error) => {
          console.error("HEIC convertion error:", error);
        });
    } else {
      const reader = new FileReader();
      reader.onload = function (e) {
        setFile(e.target.result);
        setFilePreview(true);
        if (cameraOpen) {
          setCameraOpen(false);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  function handleSubmitImage(data) {
    if (!data || submitLoading) { return }
    setSubmitLoading(true)
    uploadImage(data).then(image_url => {
      id_upload[selectedIDType] = true
      let view_complete = Object.values(id_upload).every(v => v == true)
      setComponents({ ...components, id_upload });
      handleVerify({ url: image_url, id_type: selectedIDType, view_complete })
      handleClosePreview()
    }).catch(err => {
      setSubmitDisabled(true)
    }).finally(() => {
      setSubmitLoading(false)
    })
  }


  function handleCameraStart(stream) {
    setCameraLoading(prev => false)
    if (!!cameraError) { setCameraError(prev => null) }
  }

  function handleSelectID(e, id_type) {
    setSelectedIDType(id_type)
    if (isDesktop) {
      setAnchorEl(e.currentTarget)
    } else {
      setCameraOpen(true)
    }
  }

  function SelectUpload() {
    return <Box>
      <Typography className={classes.title} variant='h3'>{t("upload_id")}</Typography>
      <div style={{ height: 20 }} />
      <Typography variant="body1" component="div">{t("accepted_ids")}:
        <ul>
          <li>{t("drivers_license")}</li>
          <li>{t("passport")}</li>
          <li>{t("other_photo_id")}</li>
        </ul>
      </Typography>
      <div style={{ height: 20 }} />
      <Popover
        id='desktop-source-select'
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        style={{ position: 'absolute' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <List disablePadding>
          <ListItem disablePadding>
            <ListItemButton onClick={e => { setCameraOpen(true); setAnchorEl(null) }}>
              <ListItemIcon style={{ minWidth: 0, marginRight: 15 }}><PhotoCameraIcon sx={{ color: 'primary.main' }} /></ListItemIcon>
              <ListItemText>{t("take_photo")}</ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={openFileExplorer}>
              <ListItemIcon style={{ minWidth: 0, marginRight: 15 }}><PhotoLibraryIcon sx={{ color: 'primary.main' }} /></ListItemIcon>
              <ListItemText>{t("upload_photo")}</ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
      {(id_upload.govt_id_front !== undefined) &&
        <VerifyItem
          disabled={Boolean(anchorEl) && selectedIDType == 'govt_id_front'}
          verified={id_upload.govt_id_front}
          margin={20} component_type='govt_id_front'
          setView={e => handleSelectID(e, 'govt_id_front')}
        />
      }
      {(id_upload.govt_id_back !== undefined) &&
        <VerifyItem
          disabled={Boolean(anchorEl) && selectedIDType == 'govt_id_back'}
          verified={id_upload.govt_id_back}
          margin={20} component_type='govt_id_back'
          setView={e => handleSelectID(e, 'govt_id_back')}
        />
      }
      {(id_upload.selfie !== undefined) &&
        <VerifyItem
          disabled={Boolean(anchorEl) && selectedIDType == 'selfie'}
          verified={id_upload.selfie}
          margin={20} component_type='selfie'
          setView={e => handleSelectID(e, 'selfie')}
        />
      }
    </Box>
  }

  function initChekinSDK(props, targetNodeID = 'chekin-sdk') {
    if (!props?.chekin_sdk_api_key || !props?.chekin_sdk_chekin_booking_id) { return }

    const ChekinHostSDK = new ChekinPro() // eslint-disable-line

    ChekinHostSDK.initialize({
      apiKey: props.chekin_sdk_api_key,
      reservationId: props.chekin_sdk_chekin_booking_id,
      onAllGuestsRegistered: () => {
        window.location.href = `${window.location.origin}/success/chekin?booking_id=${props.chekin_sdk_chekin_booking_id}`
      },
      // onGuestRegistered: (data) => console.log("GUEST:", data),
      // onReservationFound: (reservationFound) => console.log("reservationFound!", reservationFound),
    })

    ChekinHostSDK.renderApp({ targetNode: targetNodeID })
  }

  function renderSDK(sdk = null, sdkProps = null) {
    if (!sdk || !sdkProps) { return }

    if (sdk === "chekin") {
      setTimeout(() => {
        initChekinSDK(sdkProps)
      }, 5);

      return <div id='chekin-sdk' style={{ height: '93vh', width: '100%' }}></div>
    }
  }

  function renderIDUpload() {
    if (id_upload.sdk) {
      return renderSDK(id_upload.sdk, id_upload.sdk_props)
    }

    return SelectUpload()
  }

  return (<div className={classes.container}>
    <div className={classes.content}>
      <input
        accept="image/*"
        ref={inputRef}
        className={classes.input}
        id="imageInput"
        type="file"
        onChange={handleCapture}
      />
      {
        cameraOpen
          ? <Box p={2.5}>
            <div style={{ marginBottom: 10 }}>
              <Button style={{ padding: 0 }} onClick={handleCloseCamera} startIcon={<ArrowBackIosNewIcon />}>{t("back")}</Button>
            </div>
            <CameraView
              dataUri={dataUri}
              cameraLoading={cameraLoading}
              cameraError={cameraError}
              selectedIDType={selectedIDType}
              openFileExplorer={openFileExplorer}
              handleTakePhotoAnimationDone={handleTakePhotoAnimationDone}
              setCameraError={setCameraError}
              handleCameraStart={handleCameraStart}
            />
            {dataUri && <div className="footer" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', marginTop: 10 }}>
              <div style={{ width: '100%' }}>
                <Button
                  onClick={() => handleSubmitImage(dataUri)}
                  fullWidth
                  size="large"
                  variant="contained"
                  disabled={submitDisabled || submitLoading}
                  style={{ borderRadius: 30 }}
                >
                  {submitLoading ? <CircularProgress /> : (submitDisabled ? uploadFailedText : t("submit_id"))}
                </Button>
              </div>
              <Link component="button" style={{ marginTop: 10 }} onClick={() => { setDataUri(null); setSubmitDisabled(false) }}>{t("retake_photo")}</Link>
            </div>}
          </Box>
          : filePreview
            ? <Box p={2.5}>
              <div style={{ marginBottom: 10 }}>
                <Button style={{ padding: 0 }} onClick={handleClosePreview} startIcon={<ArrowBackIosNewIcon />}>{t("back")}</Button>
              </div>
              <img alt="Selected photo" src={file} className={classes.previewUploaded} />
              <div className="footer" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', marginTop: 10 }}>
                <div style={{ width: '100%' }}>
                  <Button
                    onClick={() => handleSubmitImage(file)}
                    fullWidth
                    size="large"
                    variant="contained"
                    id="submitPhoto"
                    disabled={submitDisabled || submitLoading}
                    style={{ borderRadius: 30 }}
                  >
                    {submitLoading ? <CircularProgress /> : (submitDisabled ? uploadFailedText : t("submit_photo"))}
                  </Button>
                </div>
                <Link component="button" style={{ marginTop: 10 }} onClick={openFileExplorer}>{t("change_photo")}</Link>
              </div>
            </Box>
            : <Fragment>
              <main>
                <Box p={2.5}>
                  <Button style={{ padding: 0 }} onClick={() => { setView("verify") }} startIcon={<ArrowBackIosNewIcon />}>{t("back")}</Button>
                  <div style={{ height: 10 }} />
                  {renderIDUpload()}
                </Box>
              </main>
            </Fragment>
      }
    </div>
  </div>)
}

export default CustomIDScanner
