import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { makeStyles, useTheme } from '@mui/styles';
import { hexToRgb } from 'helpers.js';


const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    textAlign: "center",
    fontWeight: "bold",
    marginTop: 30,
    paddingTop: 0
  },
  modalButton: {
    width: "100%",
    height: 70,
    borderRadius: '10px !important',
    fontSize: '18px !important',
    marginLeft: '10px !important',
    marginRight: '10px !important'
  },
  lowContrastModalButton: {
    width: "100%",
    height: 70,
    borderRadius: '10px !important',
    fontSize: '18px !important',
    marginLeft: '10px !important',
    marginRight: '10px !important',
    backgroundColor: theme.palette.primary.contrast + '!important',
    color: theme.palette.primary.main + '!important',
    border: `solid 1px ${hexToRgb(theme.palette.primary.main)} !important`,
  }
}));


export default function Modal({ title, text, buttons = [], open, setOpen }) {
  const theme = useTheme();
  const classes = useStyles();

  return <Dialog
    open={Boolean(open)}
    onClose={() => setOpen(false)}
    PaperProps={{
      style: {
        border: `solid 1px ${hexToRgb(theme.palette.primary.main)}`,
        borderRadius: 10,
        textAlign: "center",
        overflowY: 'auto',
      }
    }}
  >
    <DialogTitle className={classes.dialogTitle}>
      {title}
    </DialogTitle>
    <DialogContent
      style={{
        lineHeight: "24.5px"
      }}
    >
      {text}
    </DialogContent>
    <div style={{ marginBottom: 10 }}>
      {buttons?.map((b, i) => <React.Fragment key={b?.text ?? i}>
        {!!b && (
          <DialogActions>
            <Button
              onClick={b.onClick}
              variant="contained"
              className={b.lowContrast ? classes.lowContrastModalButton : classes.modalButton}
            >
              {b.text}
            </Button>
          </DialogActions>
        )}
      </React.Fragment>)}
    </div>
  </Dialog>
}
