import './App.css';
import React, { createContext, useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Alert, Snackbar, ThemeProvider } from "@mui/material";

import AppPages from 'routes/AppPages.jsx';
import AppRedirect from 'routes/AppRedirect.jsx';
import EnterCode from 'routes/EnterCode';
import Navbar from 'components/Navbar';
import Upgrades from 'routes/Upgrades';
import Purchases from 'routes/Purchases';
import Guides from 'routes/Guides';
import { getCookie, setCookie } from 'cookies.js';
import getTheme from 'theme';

export const KeycardContext = createContext(null)
export const AuthContext = createContext(null)

const defaultContext = {
  kc: null,
  auth: null,
  hideNavbar: null,
  alertOptions: null,
  view: null,
  setKc: null,
  setAuth: null,
  setView: null,
  setHideNavbar: null,
  setAlertOptions: null,
}

export const AppContext = createContext(defaultContext)

export const allowedViewsToShowInfo = [
  'verified',
  'checked_in',
  'start_with_info',
]

const App = () => {
  const [auth, setAuth] = useState(new URLSearchParams(window.location.search).get('auth') || getCookie('auth'))
  const [alertOptions, setAlertOptions] = useState({ open: false, message: null, severity: 'error' })
  const [isLoading, setIsLoading] = useState(true)
  const [listingId, setListingId] = useState(null)
  const [keycard, setKeycard] = useState(null)
  const [view, setView] = useState('loading')
  const [hideNavbar, setHideNavbar] = useState(false)
  const [theme, setTheme] = useState(getTheme({ color1: '#000000', color2: '#000000' }))
  let navbarAvailable = allowedViewsToShowInfo.includes(view)

  function handleSetAuth(auth) {
    setCookie('auth', auth, 30);
    setAuth(auth)
  }

  const handleClose = () => {
    setAlertOptions(prev => ({ ...prev, open: false }))
  }


  const alert = <Snackbar
    open={alertOptions.open}
    autoHideDuration={6000}
    onClose={handleClose}
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
  >
    <Alert onClose={handleClose} severity={alertOptions.severity}>{alertOptions.message}</Alert>
  </Snackbar>

  return (
    <ThemeProvider theme={theme}>
      <AppContext.Provider value={{
        auth,
        kc: keycard,
        alertOptions,
        hideNavbar,
        view,
        setView,
        setKc: setKeycard,
        setAuth: handleSetAuth,
        setHideNavbar,
        setAlertOptions,
      }}>
        <Router>
          <Navbar
            listingId={listingId}
            hide={isLoading || !navbarAvailable || hideNavbar}
          />
          <Routes>
            <Route exact path="/" element={
              <AppPages
                setTheme={setTheme}
                setIsLoading={setIsLoading}
                setListingId={setListingId}
              />
            }
            />
            <Route path="/upgrade/:upgrade_id" element={
              <AppPages
                setTheme={setTheme}
                setIsLoading={setIsLoading}
                setListingId={setListingId}
              />
            }
            />
            <Route path="/guide/:guide_id" element={
              <AppPages
                setTheme={setTheme}
                setIsLoading={setIsLoading}
                setListingId={setListingId}
              />
            }
            />
            <Route path="/entercode" element={
              <EnterCode
                setAuth={a => handleSetAuth(a)}
              />
            }
            />
            <Route path="/upgrades" element={
              <Upgrades
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                setTheme={setTheme}
              />
            }
            />
            <Route path="/purchases" element={
              <Purchases
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                setTheme={setTheme}
              />
            }
            />
            <Route path="/upgrades/:upgrade_id" element={
              <Upgrades
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                setTheme={setTheme}
              />
            }
            />
            <Route path="/guides" element={
              <Guides
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                setTheme={setTheme}
              />
            }
            />
            <Route path="/guides/:guide_id" element={
              <Guides
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                setTheme={setTheme}
              />
            }
            />
            <Route path="/:listing_id/upgrades" element={
              <Upgrades
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                setTheme={setTheme}
              />
            }
            />
            <Route path="/:listing_id/guides" element={
              <Guides
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                setTheme={setTheme}
              />
            }
            />
            <Route path="/:listing_id/upgrades/:upgrade_id" element={
              <Upgrades
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                setTheme={setTheme}
              />
            }
            />
            <Route path="/:listing_id/guides/:guide_id" element={
              <Guides
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                setTheme={setTheme}
              />
            }
            />
            <Route path="/:listing_id" element={
              <AppPages
                setTheme={setTheme}
                setIsLoading={setIsLoading}
                setListingId={setListingId}
              />
            }
            />
            <Route path="/:listing_id/upgrade/:upgrade_id" element={
              <AppPages
                setTheme={setTheme}
                setIsLoading={setIsLoading}
                setListingId={setListingId}
              />
            }
            />
            <Route path="/:listing_id/guide/:guide_id" element={
              <AppPages
                setTheme={setTheme}
                setIsLoading={setIsLoading}
                setListingId={setListingId}
              />
            }
            />
            <Route path="/stripe/:status" element={
              <AppRedirect
                setAuth={a => handleSetAuth(a)}
              />
            }
            />
            <Route path="/success/:integration" element={
              <AppRedirect auth={auth}
                setAuth={a => handleSetAuth(a)}
              />
            }
            />
          </Routes>
        </Router>
        {alert}
      </AppContext.Provider>
    </ThemeProvider>
  );
};

export default App;