import React, { useContext } from 'react';
import Typography from '@mui/material/Typography';
// components
import LargeButton from 'components/LargeButton.jsx';
import BrandingFooter from 'components/BrandingFooter.jsx';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import { AppContext } from 'App';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(0, 2.5),
    display: 'flex',
    flexDirection: 'column',
  },
}))


export default function StartPage({ setView }) {
  const { t } = useTranslation()
  const classes = useStyles()
  const { kc, auth } = useContext(AppContext)
  let components = kc?.components || {}

  function handleStartVerification() {
    if (!auth) {
      setView('details')
    } else if (Object.keys(components.completed_verification).includes('details')) {
      setView('details')
    } else {
      setView('verify')
    }
  }

  return <main>
    <div className={classes.container}>
      <Box mt={5}>
        <Typography variant='h2'>{t("lets_verify_you")}</Typography>
      </Box>
      <Box mt={7.5}>
        <LargeButton large component_type='verify' setView={() => handleStartVerification()} />
      </Box>
      <Box mt={1.5}>
        <Typography>{t("complete_before_checkin")}</Typography>
      </Box>
      <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' mt={5}>
        <BrandingFooter metadata={components?.metadata} />
      </Box>
    </div>
  </main>
}