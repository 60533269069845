import React, { useEffect, useState } from 'react'
// UI
import { makeStyles } from '@mui/styles'
// Custom
import ScrollablePage from './ScrollablePage'
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY

const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    backgroundColor: '#FFF',
    paddingTop: theme.spacing(5),
  },
}))


const StripePage = ({ onClose, link, stripeAccount }) => {
  const classes = useStyles()
  const [clientSecret, setClientSecret] = useState('')

  const stripePromise = loadStripe(STRIPE_KEY, { stripeAccount: stripeAccount })

  useEffect(() => {
    setClientSecret(link)
  }, [link])

  const content = <div className={classes.content}>
    <div id="checkout">
      {clientSecret && (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{ clientSecret }}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </div>
  </div>

  return (<ScrollablePage
    disableActions
    onClose={onClose}
    pageContent={content}
  />)
}

export default StripePage
