import {
  InstagramIcon,
  FacebookIcon,
  TwitterIcon,
  YoutubeIcon,
  UberIcon,
  ThreadsIcon,
  TikTokIcon,
  SnapchatIcon,
  LinkedInIcon,
  PinterestIcon
} from 'assets/BrandIcons'
import {
  IconButton
} from "@mui/material";


export default function BrandingFooter({ metadata, extras = <></> }) {

  function getIcon(icon) {
    let style = { color: 'primary.main', height: 35, width: 35 }
    switch (icon) {
      case 'instagram':
        return <InstagramIcon sx={style} />
      case 'twitter':
        return <TwitterIcon sx={style} />
      case 'facebook':
        return <FacebookIcon sx={style} />
      case 'youtube':
        return <YoutubeIcon sx={style} />
      case 'tiktok':
        return <TikTokIcon sx={style} />
      case 'linkedin':
        return <LinkedInIcon sx={style} />
      case 'threads':
        return <ThreadsIcon sx={style} />
      case 'pinterest':
        return <PinterestIcon sx={style} />
      case 'snapchat':
        return <SnapchatIcon sx={style} />
    }
  }

  return <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
    {!!metadata?.logo && <a href={metadata?.direct_booking_url} target="_blank" rel="noopener noreferrer">
      <img style={{ width: 150, alignSelf: 'center' }} src={metadata?.logo} />
    </a>}
    <div style={{
      marginBottom: 30,
      marginTop: 30,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      flexFlow: 'wrap',
    }}>
      {metadata?.social_profiles.map(i =>
        <IconButton key={i.account_type} onClick={() => window.open(i.url)}>{getIcon(i.account_type)}</IconButton>
      )}
      {extras}
    </div>
  </div>
}