

export function isValidAge(dateOfBirthTimestamp) {
  // Convert the timestamp to a Date object
  const dob = new Date(dateOfBirthTimestamp);

  // Calculate the age
  const today = new Date();
  const age = today.getFullYear() - dob.getFullYear();

  // Check if the birthday has occurred this year
  const hasBirthdayOccurred = (
    today.getMonth() > dob.getMonth() ||
    (today.getMonth() === dob.getMonth() && today.getDate() >= dob.getDate())
  );

  // Adjust the age if the birthday hasn't occurred yet this year
  const adjustedAge = hasBirthdayOccurred ? age : age - 1;

  // Check if the age is within the desired range (18 to 110)
  return adjustedAge >= 18 && adjustedAge <= 110;
}

export function isValidDate(date) {
  const d = new Date(date)
  return !isNaN(d)
}