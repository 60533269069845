import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "configuration/i18n.js";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";

const renderApp = async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
  });

  ReactDOM.render(
    <React.StrictMode>
      <LDProvider>
        <Suspense fallback="">
          <App />
        </Suspense>
      </LDProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );
};

renderApp();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
