import React from 'react'
import { makeStyles } from '@mui/styles'
import { Box, Typography } from '@mui/material'
import HTMLField from 'components/HTMLField'

const useStyles = makeStyles(theme => ({
  card: {
    cursor: 'pointer',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(0.5),
    padding: theme.spacing(0.5, 1, 0.5, 0.5),
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    maxWidth: 508,
    margin: props => !!props.disableGutters
      ? 0
      : theme.spacing(0, 2.5),
    '&.noImage': {
      paddingLeft: theme.spacing(1),
      maxWidth: 504,
    },
  },
  img: {
    borderRadius: 10,
    objectFit: 'cover',
    width: '30%',
    maxHeight: 80,
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
  },
  description: {
    fontSize: '13px !important',
    fontWeight: '400 !important',
    lineHeight: '20px important',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-all',
    width: '100%',
    lineClamp: 2,
  },
  name: {
    fontSize: '16px !important',
    fontWeight: '400 !important',
    lineHeight: '24px !important',
  },
}))


const RowCard = ({ resource = {}, disableGutters, onClick }) => {
  const classes = useStyles({ disableGutters })

  return (<div
    className={[classes.card, !!resource.header_image ? '' : 'noImage'].join(" ")}
    onClick={() => onClick(resource)}
  >
    {!!resource.header_image && (
      <img src={resource.header_image} className={classes.img} />
    )}
    <Box flex={1} p={1} className={classes.col}>
      <Typography className={classes.name}>{resource.name ?? ''}</Typography>
      <HTMLField content={resource?.description ?? ''} className={classes.description} />
    </Box>
  </div>)
}

export default RowCard
