import React, { useState, useEffect } from 'react'
// UI
import { ConciergeIcon } from 'assets/BrandIcons.jsx';
import axios from 'axios'
import { Box, SvgIcon, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';


const iconmap = {
  'checkin': <ConciergeIcon sx={{ fontSize: 30, color: 'primary.main' }} />,
  'checkout': <ConciergeIcon sx={{ fontSize: 30, color: 'primary.main' }} />
}


const useStyles = makeStyles(theme => ({
  root: { position: 'relative' },
  upsell_box: {
    borderRadius: 10,
    aspectRatio: '1',
    padding: '12px',
    paddingTop: 40,
    flex: 1,
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    [theme.breakpoints.up('mobile')]: {
      minWidth: 'calc((100% / 2) - 30px)',
      maxWidth: 'calc((100% / 2) - 30px)',
    },
    [theme.breakpoints.up('tablet')]: {
      minWidth: 'calc((100% / 3) - 32px)',
      maxWidth: 'calc((100% / 3) - 32px)',
    },
    [theme.breakpoints.up('xtablet')]: {
      minWidth: 'calc((100% / 4) - 36px)',
      maxWidth: 'calc((100% / 4) - 36px)',
    },
  },
  checkbox: {
    position: 'absolute',
    top: 7,
    right: 7,
    padding: theme.spacing(0.5),
    zIndex: 1,
    '& svg': { fontSize: 15 },
  },
  logo: {
    height: 45,
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
  },
  customIcon: {
    width: '100% !important',
    maxHeight: 102,
    aspectRatio: '1',
    [theme.breakpoints.up('mobile')]: { maxWidth: '65%' },
    [theme.breakpoints.up('tablet')]: { maxWidth: '85%' },
    [theme.breakpoints.up('xtablet')]: { maxWidth: '100%' },
  },
  name: {
    fontSize: '16px !important',
    fontWeight: '400 !important',
    lineHeight: '24px !important',
    minHeight: 48,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-word',
    width: '100%',
    lineClamp: 3,
  },
  editBtn: {
    padding: 0,
    minWidth: 'fit-content',
    fontWeight: 400,
    fontSize: 14,
  },
}))


export default function ContainedCard({ upsell, openUrl }) {
  const classes = useStyles()
  const [product, setProduct] = useState(null)
  let icon = !!upsell ? iconmap[upsell.product_type] : null


  useEffect(() => {
    if (!upsell) { return }
    console.log("FETCHING PRODUCT")
    axios.get(`https://enso-image-assets.s3.amazonaws.com/products/${upsell.product_type}_product.json`).then(r =>
      setProduct(r.data)
    )
  }, [upsell])


  let custom_icon = product && <SvgIcon
    viewBox={product.viewBox}
    style={{ fontSize: 100 }}
    className={classes.customIcon}
    color="primary">
    <path d={product.path} />
  </SvgIcon>


  return (
    <div
      onClick={() => openUrl(upsell)}
      className={classes.upsell_box}
      tabIndex={0}
      id={upsell?.product_id ?? undefined}
    >
      <Box display={'flex'} alignItems={'center'} width={'100%'}>
        <div>
          {icon || custom_icon}
        </div>
      </Box>
      <Typography className={classes.name}>{upsell?.name ?? ''}</Typography>
    </div>
  )
}
