import React from 'react'
import { makeStyles } from '@mui/styles'
import { Typography } from '@mui/material'
import { gradientRgb } from 'helpers'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    maxHeight: 230,
    minHeight: 190,
    background: props => `linear-gradient(180deg, rgba(255,255,255,0) 30%, ${gradientRgb(theme.palette.primary.contrast)} 95%), url("${props.img}") no-repeat center center / cover`,
    backgroundPosition: 'center !important',
    backgroundRepeat: 'no-repeat !important',
    backgroundSize: 'cover !important',
    width: '100vw',
    alignItems: 'center',
  },
  content: {
    width: '100%',
    maxWidth: 800,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 12,
  },
  title: { padding: theme.spacing(0, 2.5) },
}))


const SectionHeaderCard = ({ img, title, actions, titleRef, disableGrayscale }) => {
  const classes = useStyles({ img, disableGrayscale })

  return (<div className={classes.container}>
    <div className={classes.content} ref={titleRef}>
      <Typography variant='h3' component={'div'} className={classes.title}>
        {title}
      </Typography>
      {!!actions && actions}
    </div>
  </div>)
}

export default SectionHeaderCard
