import React from 'react';
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import MapIcon from '@mui/icons-material/Map';
import ListIcon from '@mui/icons-material/List';

const ToggleButtonComponent = ({ view, handleViewChange }) => {
  return (
    <ToggleButtonGroup
      value={view}
      exclusive
      onChange={handleViewChange}
      sx={{
        position: 'fixed',
        top: 16,
        right: 16,
        zIndex: 1,
        bgcolor: 'background.paper',
        borderRadius: '12px',
        boxShadow: 3,
      }}
    >
      <ToggleButton value="maps" sx={{ borderRadius: '12px 0 0 12px' }}>
        <MapIcon sx={{ mr: 1 }} />
        <Typography style={{color: 'inherit'}}>Map</Typography>
      </ToggleButton>
      <ToggleButton value="guides" sx={{ borderRadius: '0 12px 12px 0' }}>
        <ListIcon sx={{ mr: 1 }} />
        <Typography style={{color: 'inherit'}}>Guides</Typography>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default ToggleButtonComponent;
