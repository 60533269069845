import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import { AppContext } from 'App'
import { Box, Modal, Typography } from '@mui/material'
import GuidebookPage from 'components/GuidebookPage'
import SimpleCard from 'components/Cards/SimpleCard'
import HorizontalCard from 'components/Cards/HorizontalCard'
import DescriptionCard from 'components/Cards/DescriptionCard'

const previewItems = 3

const useStyles = makeStyles(theme => ({
  container: {
    margin: 0,
    paddingTop: props => !!props.preview ? 0 : 12,
  },
  grid: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: theme.spacing(1.5),
  },
  line: {
    height: 1,
    border: 'none',
    opacity: '10%',
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    marginBottom: theme.spacing(3),
  },
}))


const GuidebooksGrid = ({ preview = false, divider }) => {
  const classes = useStyles({ preview })
  const { kc, auth, setView, setHideNavbar } = useContext(AppContext)
  const navigate = useNavigate()
  const location = useLocation()
  const { listing_id, guide_id } = useParams()
  const { t } = useTranslation()
  const nextLargeCardIndex = useRef(0)
  const [guides, setGuides] = useState([])
  const [selectedGuide, setSelectedGuide] = useState(null)
  let components = kc?.components ?? {}


  useEffect(() => {
    if (guide_id != null && selectedGuide === null) {
      setSelectedGuide(prev => guide_id)
      setHideNavbar(true)
    } else if (guide_id == null && selectedGuide !== null) {
      handleCloseGuide(true)
    }
  }, [guide_id])

  useEffect(() => {
    if (!kc) { return }
    setGuides(prev => components.guidebooks || [])
  }, [kc])


  const openGuide = (guideId) => {
    let guidesPath = location.pathname.includes('/guides') ? '/guides' : '/guide'
    let path = !!listing_id
      ? `/${listing_id}${guidesPath}/${guideId}`
      : `${guidesPath}/${guideId}/?auth=${auth}`
    navigate(path)
  }

  const handleCloseGuide = (replace) => {
    setSelectedGuide(prev => null)
    setHideNavbar(false)
    setView(kc.view)
    let guidesPath = location.pathname.includes('/guides') ? '/guides' : ''
    let path = !!listing_id
      ? `/${listing_id}${guidesPath}`
      : `${guidesPath}/?auth=${auth}`
    navigate(path, { replace: !!replace })
  }

  const goToGuides = () => {
    let path = !!listing_id
      ? `/${listing_id}/guides`
      : `/guides/?auth=${auth}`
    navigate(path, { state: { section: "guides" } })
  }


  if (!guides.length) { return null }
  return (<div className={classes.container}>
    {(selectedGuide !== null) && (
      <Modal open>
        <div>
          <GuidebookPage
            guidebookId={selectedGuide}
            components={components}
            closePage={handleCloseGuide}
          />
        </div>
      </Modal>
    )}
    {!!preview && (<>
      {!!divider && <hr className={classes.line} />}
      <Box pb={1.5}>
        <Typography variant='body2'>{t("guides").toUpperCase()}</Typography>
      </Box>
    </>)}
    <Box className={classes.grid}>
      {guides.map((gb, i) => {
        if (!!preview && i >= previewItems) { return null }
        else if (i === 0
          || i === nextLargeCardIndex.current
          || (i === (guides.length - 1) && (nextLargeCardIndex.current - (guides.length - 1)) % 2 === 0)
        ) {
          nextLargeCardIndex.current = i + 5
          return (<HorizontalCard
            key={`gb-${i}`}
            resource={gb}
            onClick={() => openGuide(gb.id)}
          />)
        } else {
          return (<DescriptionCard
            key={`gb-${i}`}
            resource={gb}
            onClick={() => openGuide(gb.id)}
          />)
        }
      })}
    </Box>
    {!!preview && (
      <Box mt={1.5}>
        <SimpleCard
          disableGutters
          onClick={goToGuides}
          label={`${t("view")} ${(guides.length - previewItems) > 0
            ? ((guides.length - previewItems) + ' ')
            : ''}${t("more")}`}
        />
      </Box>
    )}
  </div>)
}

export default GuidebooksGrid
