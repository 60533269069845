import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import CalendarIcon from 'assets/CalendarIcon.jsx';
import CheckInIcon from 'assets/CheckInIcon.jsx';
import { ReviewIcon } from 'assets/Icons.jsx';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { makeStyles, useTheme } from '@mui/styles';
import { hexToRgb } from 'helpers.js';
import { useTranslation } from 'react-i18next';



const useStyles = makeStyles({
	box: {
		marginBottom: 15,
		borderRadius: '10px'
	}
});


const LargeButton = ({ disabled, large, component_type, setView, margin = 0 }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const theme = useTheme();
	let reverseColor = ["verify","check_in_steps","check_out_steps"].includes(component_type)
	let spec = {
		check_in_steps: {
			name: t("checkin"),
			id: "checkinButton",
			padding: 15,
			icon: <CheckInIcon sx={{ color: 'primary.contrast' }} />
		},
		check_out_steps: {
			name: t("checkout"),
			id: "checkoutButton",
			padding: 15,
			icon: <CheckInIcon sx={{ color: 'primary.contrast' }} />
		},
		verify: {
			name: t("verify_me"),
			id: "verifyme",
			padding: large ? 30 : 15,
			icon: <VerifiedUserIcon sx={{ color: 'primary.contrast', fontSize: 30 }} />
		},
		leave_review: {
			name: t("leave_review"),
			id: "leave_review",
			padding: 15,
			icon: <ReviewIcon sx={{ color: 'primary.main' }} />
		},
		book_again: {
			name: t("book_again"),
			id: "booking_again",
			padding: 15,
			icon: <CalendarIcon sx={{ color: 'primary.main' }} />
		}
	}[component_type]

	return (<ListItem
		key={component_type}
		style={{
			border: reverseColor ? 'none' : `1.5px solid ${hexToRgb(theme.palette.primary.main)}`,
			margin: 0, marginBottom: margin, minWidth: '220px',
			backgroundColor: reverseColor ? theme.palette.primary.main : 'transparent',
		}}
		className={classes.box}
		disablePadding
	>
		<ListItemButton style={{ display: 'flex', justifyContent: 'center', padding: spec.padding }} disabled={disabled} onClick={() => setView && setView(component_type)}>
			<ListItemIcon style={{ minWidth: 0, marginRight: 10 }}>
				{spec.icon}
			</ListItemIcon>
			<Typography 
				variant='h5' 
				id={spec.id} 
				sx={{color: reverseColor ? 'primary.contrast': 'primary.main'}}
			>{spec.name}</Typography>
		</ListItemButton>
	</ListItem>)
}

export default LargeButton

