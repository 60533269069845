import React, { useContext, useEffect, useState } from 'react';
// UI
import { makeStyles } from '@mui/styles';
import {
  Button,
  ListItem,
  ListItemButton,
  ListItemIcon,
  List,
  ListItemText,
  Radio,
  Typography,
  darken,
  Box,
} from "@mui/material";
// Custom
import LoadingSpinner from 'components/LoadingSpinner';
import HTMLContent from 'components/HTMLContent';
import ScrollablePage from 'components/ScrollablePage';
import SectionHeaderCard from './Cards/SectionHeaderCard';
// Utils
import defaultBGImg from 'assets/article_placeholder_image.webp'
import { getPurchase, savePurchase } from 'apiActions';
import useRefDimensions from 'useRefDimensions';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'App';

const useStyles = makeStyles((theme) => ({
  logo: {
    height: 50,
    marginBottom: 30,
  },
  sku_box: {
    marginBottom: 12,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(5, 2.5, 0),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2.5),
    '& .ql-editor': {
      padding: '0 !important',
      '& img': { maxWidth: '100%' },
    },
  },
  btn: {
    flex: 1,
    fontWeight: '500 !important',
    fontSize: '16px !important',
    borderRadius: '30px !important',
    fontFamily: '"Montserrat", serif !important',
  },
  sku_item: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 10,
    padding: theme.spacing(0.5, 1, 0.5, 1.5),
    '&.disabled': {
      border: 'none',
      background: darken(theme.palette.primary.contrast, 0.06),
    },
    '&.selected': { background: darken(theme.palette.primary.contrast, 0.07) },
  },
  itemIcon: {
    minWidth: '0px !important',
    marginRight: theme.spacing(1.5),
  },
  radioBtn: {
    padding: '0px !important',
    color: theme.palette.primary.main + '!important',
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  price: {
    borderRadius: 5,
    padding: theme.spacing(0.5, 1),
    background: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main) + '!important',
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  itemDescription: {
    flex: 1,
    paddingRight: theme.spacing(1),
  },
  simpleHeadercontainer: {
    width: '100%',
    maxWidth: 800,
    paddingTop: theme.spacing(7.5),
  },
}));



function findProduct(components, product_id) {
  let product = components.fees.find(f => f.product_id === product_id)
  if (!!product) {
    return [product, 'fee']
  } else {
    return [components.upsells.find(f => f.product_id === product_id), 'up']
  }
}


export default function ProductPage({
  product_id, onClose, onPaymentRedirect, setStripeAccount = () => { }
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const contentRef = React.useRef(null)
  const [url, setUrl] = useState(null)
  const { kc, auth, view, setView, setAlertOptions } = useContext(AppContext)
  const [purchaseGetError, setPurchaseGetError] = useState(false)
  let components = kc?.components || {}
  let booking_id = kc?.booking_id
  const [product, product_resource] = findProduct(components, product_id)
  const is_upsell = product_resource == "up"
  const is_free_upsell = is_upsell && !product?.skus.find(s => s.value > 0)
  const multiple_skus = product?.skus?.length > 1
  const [selectedSku, setSelectedSku] = React.useState(multiple_skus ? null : 0)
  const [visibleBottom, setVisibleBottom] = React.useState(false)
  const [isPurchasing, setIsPurchasing] = React.useState(false)
  const contentDimensions = useRefDimensions(contentRef)
  const productTotal = selectedSku === null
    ? ''
    : !product?.skus?.[selectedSku].value ? '' : `${product?.skus?.[selectedSku].formatted_value} - `
  const purchaseWord = selectedSku === null
    ? t("make_your_selection")
    : product?.skus[selectedSku].charge_card === false
      ? t("place_hold")
      : !product?.skus[selectedSku].value
        ? t("request")
        : t("purchase")

  useEffect(() => {
    if (!is_free_upsell && url === null) {
      getPurchase({
        auth, booking_id, product_id, resource: product_resource,
        onSuccess: (response) => {
          setUrl(response.data.url)
          setStripeAccount(response.data.url?.account_id ?? null)
        },
        onError: () => { setPurchaseGetError(true) },
      })
    }
  }, [product_id])

  useEffect(() => {
    if (contentDimensions.width === 1 && contentDimensions.height === 2) { return }
    if (contentDimensions.height === contentRef.current?.scrollHeight && !visibleBottom) {
      setVisibleBottom(prev => true)
    } else if (contentDimensions.height < contentRef.current?.scrollHeight && !!visibleBottom) {
      setVisibleBottom(prev => false)
    }
  }, [product, contentDimensions])


  const scrollToBottom = () => {
    contentRef.current?.scrollTo({ top: contentRef.current?.scrollHeight ?? 0, behavior: 'smooth' })
  }

  function getSku(ind) {
    let curr_sku = product.skus[ind]
    return <ListItem
      key={`sku-${ind}`}
      disablePadding
      className={classes.sku_item}
    >
      <ListItemButton role={undefined} onClick={() => setSelectedSku(ind)} dense>
        <ListItemIcon className={classes.itemIcon}>
          <Radio
            checked={ind === selectedSku}
            tabIndex={-1}
            className={classes.radioBtn}
            value={`skuselect-${ind}`}
          />
        </ListItemIcon>
        <ListItemText disableTypography primary={<div className={classes.itemContainer}>
          <Typography className={classes.itemDescription}>{curr_sku.name}</Typography>
          <Typography className={classes.price}>{curr_sku.formatted_value}</Typography>
        </div>}
        />
      </ListItemButton>
    </ListItem>
  }

  const handlePurchase = () => {
    if (selectedSku === null) {
      scrollToBottom()
      return
    }

    let sku = product.skus[selectedSku]
    let sku_id = sku.sku_id;

    if (sku.value == 0) {
      setIsPurchasing(true)
      let body = { auth, sku_id, resource: product_resource, booking_id }
      savePurchase({ body, setAlertOptions, onSuccess: () => setIsPurchasing(false), onError: () => setIsPurchasing(false) })
    } else {
      let action = url[sku_id]

      if (!!action.external_redirect) {
        window.open(action.url)
      } else if (!!action.payment_redirect) {
        onPaymentRedirect(action.url)
      } else {
        let body = {
          auth: auth,
          session_id: action.session_id,
          sku_id: sku_id,
          resource: product_resource,
          booking_id: booking_id
        }
        setIsPurchasing(true)
        savePurchase({ body, setAlertOptions, onSuccess: () => setIsPurchasing(false), onError: () => setIsPurchasing(false) })
      }
    }
  }
  let notLoaded = url === null && !is_free_upsell

  const getPurchaseButtonContent = () => {
    if (notLoaded) {
      if (purchaseGetError) {
        return "Failed to Load - Please Contact your Host"
      }
      return <LoadingSpinner />
    }
    return <span>{(selectedSku !== null) && productTotal}{purchaseWord}</span>
  }

  const handleContentScroll = (e) => {
    const el = e.target
    if (!!el) {
      let scrollBottom = el.scrollHeight - el.scrollTop - el.clientHeight
      if (scrollBottom === 0 && !visibleBottom) { setVisibleBottom(prev => true) }
      else if (scrollBottom > 0 && !!visibleBottom) { setVisibleBottom(prev => false) }
    }
  }

  const header = !!product.header_image
    ? <SectionHeaderCard
      disableGrayscale
      title={product?.name ?? ''}
      img={product?.header_image ?? defaultBGImg}
    />
    : <div className={classes.simpleHeadercontainer}>
      <Box px={2.5}>
        <Typography variant='h3'>{product.name}</Typography>
      </Box>
    </div>

  const productContent = <div className={classes.content}>
    <HTMLContent v={product.description} />
    {multiple_skus && <List className={classes.sku_box}>
      {product.skus.map((sku, i) => getSku(i))}
    </List>}
  </div>

  const actionPanel = <div className={classes.actionsContainer}>
    <ListItem
      disablePadding
      className={`${classes.sku_item} ${selectedSku === null ? 'disabled' : 'selected'}`}
    >
      <ListItemButton disableRipple role={undefined} dense>
        <ListItemText disableTypography primary={<div className={classes.itemContainer}>
          <Typography className={classes.itemDescription} textAlign={selectedSku === null ? 'center' : 'left'}>
            {product.skus[selectedSku]?.name ?? t('nothing_selected')}
          </Typography>
          {selectedSku !== null && (
            <Typography className={classes.price}>{product.skus[selectedSku].formatted_value}</Typography>
          )}
        </div>}
        />
      </ListItemButton>
    </ListItem>
    <Button
      disableRipple
      disabled={notLoaded || isPurchasing}
      className={classes.btn}
      onClick={handlePurchase} fullWidth size="large" variant="contained"
    >
      {getPurchaseButtonContent()}
    </Button>
  </div>



  return <ScrollablePage
    onClose={() => {
      if (onClose) { onClose() }
      else {
        if (is_upsell) { setView(view) }
        else { setView("verify") }
      }
    }}
    actionPanel={actionPanel}
    contentRef={contentRef}
    contentType='product'
    handleContentScroll={handleContentScroll}
    header={header}
    pageContent={productContent}
  />
}