import React, { useState } from "react";
import {
  Typography,
  ListItem,
  ListItemIcon,
  ListItemButton,
  Grid,
  Link,
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { getAbsoluteUrl, hexToRgb } from 'helpers.js'
import ContactInfo from "./ContactInfo";
import { useTranslation } from "react-i18next";


const useStyles = makeStyles({
  box: {
    borderRadius: 999,
    margin: 0,
    marginBottom: 10,
    '&.right-aligned': { minWidth: '0!important' },
  },
  link: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: '100%',
  },
});



export default function LockButton({ state, handleUnlock, components, device_id, hideCode = false, showLink }) {

  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false)
  const [codeLoading, setCodeLoading] = useState(false)
  const [recoveryFailed, setRecoveryFailed] = useState(false)
  const statusIsSet = (!state?.smart_lock_code_status) || state?.smart_lock_code_status === 'set'

  let color = theme.palette.primary.main
  const LOCK_STATES = {
    locked: {
      lock_loading: false,
      lock_button: t("unlock_door"),
      icon: <LockOpenIcon sx={{ color: color }} />,
      locked: true
    },
    unlocked: {
      lock_loading: false,
      lock_button: t("lock_door"),
      icon: <LockIcon sx={{ color: color }} />,
      locked: false
    }
  }
  const LOADING_STATES = {
    locked: {
      lock_loading: true,
      lock_button: `${t("unlocking_door")}...`,
      icon: <CircularProgress size={20} style={{ color: color }} />,
      locked: null
    },
    unlocked: {
      lock_loading: true,
      lock_button: `${t("locking_door")}...`,
      icon: <CircularProgress size={20} style={{ color: color }} />,
      locked: null
    }
  }
  const button_state = state.hasOwnProperty("smart_lock") && loading ? LOADING_STATES[state.smart_lock] : LOCK_STATES[state.smart_lock]

  function unlockDoor() {
    setLoading(true)
    handleUnlock(device_id, button_state.locked ? "unlock" : "lock").then(() => setLoading(false))
  }

  function requestRecoveryCode() {
    setCodeLoading(true)
    handleUnlock(device_id, "recover").then(response => { setCodeLoading(false) })
  }

  function handleCodeNotWorking() {
    if (!state.recovery_code) { requestRecoveryCode() }
    else { setRecoveryFailed(true) }
  }

  function handleUrlClick(url) {
    let absoluteUrl = getAbsoluteUrl(url)
    const newWindow = window.open(absoluteUrl, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  let error_msg = (state.error || recoveryFailed) && <div>
    <div style={{ backgroundColor: theme.palette.primary.main, borderRadius: 10, padding: 10, marginBottom: 15 }}>
      <Typography style={{ color: theme.palette.primary.contrastText }}>{state.error || t("contact_host")}</Typography>
    </div>
    <ContactInfo components={components} />
  </div>

  let unset_code = (!!state.smart_lock_code && !hideCode && !statusIsSet) && <div>
    <div style={{ backgroundColor: theme.palette.primary.main, borderRadius: 10, padding: 10, marginBottom: 15 }}>
      <Typography style={{ color: theme.palette.primary.contrastText }}>{t("lock_code_unset_warning")}</Typography>
    </div>
  </div>

  let not_working_button = <Grid className={classes.link}>
    <Link
      onClick={() => handleCodeNotWorking()}
      style={{ pointerEvents: codeLoading ? 'none' : '' }}
    >{t("code_not_working")}</Link>
  </Grid>

  let code = <Grid flex="1">
    <Typography variant='h5'>{codeLoading ? (<CircularProgress size={20} style={{ color: color }} />) : state?.smart_lock_code}</Typography>
  </Grid>

  function getButton(rightAligned) {
    return (button_state || showLink) && <ListItem
      id={`lock-${device_id}`}
      style={{ border: `1.5px solid ${hexToRgb(theme.palette.primary.main)}`, margin: 0, marginTop: showLink ? 10 : 0, marginBottom: 10, minWidth: '220px' }}
      className={`${classes.box} ${rightAligned ? 'right-aligned' : ''}`}
      disablePadding
    >
      <ListItemButton
        style={{ display: 'flex', justifyContent: 'center', padding: 10 }}
        disabled={!showLink && (button_state.lock_loading || !statusIsSet)}
        onClick={() => showLink ? handleUrlClick(state.link_url) : unlockDoor()}
      >
        {!showLink && <ListItemIcon style={{ minWidth: 0, marginRight: 10 }}>
          {button_state.icon}
        </ListItemIcon>}
        <Typography variant='h6'>
          {showLink ? (state.link_label || '') : button_state.lock_button}
        </Typography>
      </ListItemButton>
    </ListItem>
  }


  return <>
    <Typography variant='body2'>{state.smart_lock_name}</Typography>
    {(!!state.smart_lock_code && !hideCode)
      ? <Grid container sx={{ mb: 2 }}>
        <Grid flex="1" alignSelf={'center'}>
          {code}
        </Grid>
        <Grid alignSelf={'center'}>{getButton(true)}</Grid>
      </Grid>
      : getButton()
    }
    {(!!state.smart_lock_code && !hideCode && statusIsSet) && (
      <Grid container sx={{ mb: 2 }}>
        {not_working_button}
      </Grid>
    )}
    {unset_code}
    {error_msg}
  </>

}