import React from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';


export default function LoadingScreen(){
	const { t } = useTranslation()

	return <div className="primary_content">
	  <div style={{height: 60}}/>
	  <div className="spinner">
	    <div className="bounce1"></div>
	    <div className="bounce2"></div>
	    <div className="bounce3"></div>
	  </div>
	  <div style={{height: 60}}/>
	  <Typography variant='h4'>{t("loading")}...</Typography>
	</div>
}